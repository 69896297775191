<template>
  <div class="main-container">
    <div class="head_wrap">
      <dd-card title="条件搜索" spacer>
        <dd-search-form
          :form-config="formList"
          @search-click="handleSearchEvent"
          ref="ddSearchForm"
        ></dd-search-form>
      </dd-card>
    </div>
    <dd-card title="合同列表" :height="tableHeight">
      <dd-table
        :columns="tableColumns"
        :data="tableData"
        border
        :isSelection="true"
        show-page
        :loading="loading"
      >
        <template #table-btn-right>
          <el-button
            icon="el-icon-s-operation"
            circle
            type="info"
            size="small"
            @click="drawerVisible = true"
            title="列筛选"
          ></el-button>
        </template>
        <template #table-pagination>
          <dd-pagination
            v-show="total > 0"
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handleCurrentChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="drawerVisible = false"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>
  </div>
</template>

<script>
import { getApi, delApi, postApi, putApi } from "@/api/request";
export default {
  name: "tenement",
  $dynamicRoute: {
    name: "合同列表",
    path: "/contract/index",
  },
  components: {
    // addPop,
  },
  computed: {},
  data() {
    return {
      tableHeight: null,
      drawerVisible: false,
      loading: true,
      formData: {},
      formList: [
        {
          label: "合同编号",
          value: "code",
          inputType: "text",
          placeholderText: "请输入合同编号",
        },
        {
          label: "合同名称",
          value: "name",
          inputType: "text",
          placeholderText: "请输入合同名称",
        },
        {
          label: "合同所属部门",
          value: "contract_dept_id",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择部门",
          optionsListConfig: {
            name: "label",
            code: "value",
          },
        },
        {
          label: "合同年份",
          value: "year",
          inputType: "date",
          dateType: "year",
          format: "yyyy",
          placeholderText: "请选择年份",
        },{},{},{}
      ],
      tableColumns: [
        {
          title: "合同编号",
          key: "code",
          align: "center",
          width: 160,
        },
        {
          title: "合同名称",
          key: "name",
          align: "center",
          width: 230,
          overflow: true,
        },
        {
          title: "合同区域",
          key: "contractTypeName",
          align: "center",
        },
        {
          title: "甲方",
          key: "partAId",
          align: "center",
        },
        {
          title: "甲方签约人",
          key: "personA",
          align: "center",
        },
        {
          title: "甲方签约日期",
          key: "signDateA",
          align: "center",
        },
        {
          title: "甲方代表",
          key: "deputyA",
          align: "center",
        },
        {
          title: "乙方",
          key: "partBId",
          align: "center",
        },
        {
          title: "乙方签约人",
          key: "personB",
          align: "center",
        },
        {
          title: "乙方签约日期",
          key: "signDateB",
          align: "center",
        },
        {
          title: "乙方负责人",
          key: "deputB",
          align: "center",
        },
        {
          title: "签订地点",
          key: "signPlace",
          align: "center",
        },
        {
          title: "开工日期",
          key: "contractBeginTime",
          align: "center",
        },
        {
          title: "竣工日期",
          key: "contractFinishTime",
          align: "center",
        },
        {
          title: "总金额",
          key: "foriginalamount",
          align: "center",
          width: 100,
        },
        {
          title: "项目名称",
          key: "prjName",
          align: "center",
        },
        {
          title: "合同所属部门",
          key: "contractDeptName",
          align: "center",
        },
        {
          title: "经办人",
          key: "handlerPersonName",
          align: "center",
        },
        {
          title: "审核人",
          key: "auditorName",
          align: "center",
        },
        // {
        //   title: "操作",
        //   align: "center",
        //   fixed: "right",
        //   overflow: true,
        //   width: 100,
        // },
      ],
      tableData: [],
      current: 1,
      size: 10,
      total: null,
      dictType: [],
      addPopupVisible: false,
      rowData: null,
      poptitleName: "新增",
    };
  },
  created() {},
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.getContractTypes();
    this.getTableData();
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    // 获取表格列表数据
    getTableData() {
      let { formData, current, size } = this;
      let params = {
        ...formData,
        current,
        size,
      };
      this.loading = true;
      getApi(`/project/contractinfo/page`, params).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.loading = false;
          this.tableData = data.data.records;
          this.total = data.data.total;
        }
      });
    },
    /* 搜索栏 */
    handleSearchEvent(obj) {
      this.formData = obj;
      this.current = 1;
      this.getTableData();
    },
    /* 分页页码回调 */
    handleCurrentChange(size, number) {
      this.current = number;
      this.size = size;
      this.getTableData();
    },
    getContractTypes() {
      let params = {
        typeCode: "htlx",
      };
      getApi(`/project/contract/dict/list`, params).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          let options = {
            value: "contractTypeCode",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, options);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped></style>
