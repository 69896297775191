<template>
  <div class="main-container">
    <dd-card title="基本信息" spacer
      ><dd-form
        :form-config="formBasicList"
        :flex="3"
        labelWidth="140px"
        ref="expenseBasicForm"
        :formRef="formBasicRef"
      >
      </dd-form>
    </dd-card>
    <dd-card title="费用明细">
      <dd-form
        :form-config="formDetailList"
        :flex="3"
        ref="expenseDetailForm"
        :formRef="formDetailRef"
        labelWidth="130px"
      ></dd-form>
      <el-link
        type="warning"
        :underline="false"
        icon="el-icon-info"
        style="margin-left: 95px"
        >请填写该笔费用的具体用途(时间，地点、数量、规格、型号、具体名称、用途)</el-link
      >
    </dd-card>
    <dd-card title="上传附件">
      <div class="ibox-content" style="height: 200px; overflow: auto">
        <el-upload
          :auto-upload="true"
          class="upload-demo"
          ref="upload"
          action="/admin/sys-file/upload"
          :headers="headers"
          :on-remove="handleRemove"
          :on-success="beforeSuccess"
          multiple
          :limit="10"
          :on-exceed="handleExceed"
          :before-upload="beforeAvatarUpload"
          :file-list="fileList"
        >
          <el-button size="small" type="primary"
            ><span class="iconfont" style="margin-right: 5px"></span>
            选择文件
          </el-button>
          <div slot="tip" class="el-upload__tip">
            <i>*</i> 只能上传图片文件,且不超过5M
          </div>
          <div slot="tip" class="el-upload__tip" ref="filesValid"></div>
        </el-upload>
      </div>
    </dd-card>
  </div>
</template>

<script>
import { postApi, getApi } from "@/api/request";
import { getNowDate } from "@/utils/date";
export default {
  name: "add-pop",
  props: {
    expenseApply: {},
    depList: [],
    expenseType: [],
    projectTree: [],
  },
  data() {
    return {
      formBasicRef: "expenseBasicForm",
      formDetailRef: "expenseDetailForm",
      // 请求头信息
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("authToken") || "",
      },
      //附件列表
      fileList: [],
      fileListParam: [],
      //项目列表
      project: [],
      assignAndProjectObj: {},
      formBasicList: [
        {
          label: "申请人",
          value: "applier",
          inputType: "text",
          placeholderText: "",
          disabled: true,
          defaultValue: sessionStorage.getItem("fullName"),
        },
        {
          label: "申请日期",
          value: "applyTime",
          inputType: "text",
          disabled: true,
          defaultValue: getNowDate(),
        },
        {
          label: "部门",
          value: "deptName",
          inputType: "text",
          placeholderText: "",
          disabled: true,
          defaultValue: sessionStorage.getItem("depName"),
        },
        {
          label: "任务书名称",
          value: "assignName",
          inputType: "select",
          optionsList: [],
          filterable: "filterable",
          placeholderText: "请选择或搜索任务书名称",
          optionsListConfig: {
            name: "assignName",
            code: "assignId",
          },
          change: this.changeAssignNameAndNum,
          rules: [
            {
              required: true,
              message: "请选择任务书名称",
              trigger: "blur",
            },
          ],
          remote: true,
          remoteMethod: (query) => {
            this.getAssignList(query);
          },
        },
        {
          label: "任务书编码",
          value: "assignNum",
          inputType: "select",
          optionsList: [],
          filterable: "filterable",
          placeholderText: "请选择或搜索任务书名称",
          optionsListConfig: {
            name: "assignNum",
            code: "assignId",
          },
          change: this.changeAssignNameAndNum,
          rules: [
            {
              required: true,
              message: "请选择任务书名称",
              trigger: "blur",
            },
          ],
          remote: true,
          remoteMethod: (query) => {
            this.getAssignList(query);
          },
        },
        {
          label: "项目名称",
          value: "projectId",
          inputType: "select",
          optionsList: [],
          filterable: "filterable",
          disabled: true,
          placeholderText: "请选择或搜索项目",
          optionsListConfig: {
            name: "projectName",
            code: "projectId",
          },
          // change: this.changeProject,
          rules: [
            {
              required: true,
              message: "请选择项目",
              trigger: "blur",
            },
          ],
        },
        {
          label: "费用承担部门",
          value: "expenseBearDepId",
          inputType: "select",
          optionsList: [],
          defaultValue: sessionStorage.getItem("depId"),
          placeholderText: "请选择费用承担部门",
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
          rules: [
            {
              required: true,
              message: "请选择费用承担部门",
              trigger: "blur",
            },
          ],
        },
        {
          label: "费用承担公司",
          value: "expenseBearOrg",
          inputType: "text",
          placeholderText: "",
          disabled: true,
          defaultValue: sessionStorage.getItem("orgName"),
        },
        // {
        //   label: "验证人",
        //   value: "verifierId",
        //   inputType: "select",
        //   optionsList: [],
        //   defaultValue: "",
        //   filterable: true,
        //   placeholderText: "请选择验证人",
        //   optionsListConfig: {
        //     name: "fullName",
        //     code: "userId",
        //   },
        //   rules: [
        //     {
        //       required: true,
        //       message: "请选择验证人",
        //       trigger: "blur",
        //     },
        //   ],
        // },
      ],
      formDetailList: [
        {
          label: "费用类型",
          value: "expenseTypeId",
          inputType: "cascader",
          optionsList: [],
          prop: {
            value: "id",
            label: "name",
          },
          // change: this.handleExpenseType,
          placeholderText: "请选择费用类型",
          rules: [
            {
              required: true,
              message: "请选择费用类型",
              trigger: "blur",
            },
          ],
          defaultValue: "",
        },
        // {
        //   label: "剩余预算(元)",
        //   value: "residueAmount",
        //   inputType: "text",
        //   defaultValue: "",
        //   disabled: true,
        //   placeholderText: "",
        // },
        {
          label: "申请金额(元)",
          value: "amount",
          inputType: "text",
          placeholderText: "请输入金额",
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请输入申请金额",
              trigger: "blur",
            },
          ],
        },
        {
          label: "事由",
          value: "subjectMatter",
          inputType: "text",
          type: "textarea",
          autosize: { minRows: 2 },
          placeholderText: "请输入内容",
          rules: [
            {
              required: true,
              message: "请输入申请事由",
              trigger: "blur",
            },
          ],
        },
      ],
      //剩余金额传参
      // residueAmountObj: {
      //   projectId: "",
      //   expenseTypeId: "",
      // },
    };
  },
  created() {
    // this.getAssignName();
    this.getAssignList();
  },
  mounted() {
    let deptOptions = {
      value: "expenseBearDepId",
      optionsList: this.depList,
    };
    // 费用类型
    // this.setOPtionsList(this.formDetailList, expenseTypeOptions);
    // 部门数据
    this.setOPtionsList(this.formBasicList, deptOptions);
    this.getDataType();
    // 编辑请求详情
    if (this.expenseApply.type == "1") {
      this.getDataDetail();
    }
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    getExpenseType(e) {
      postApi('/finance/expense/query/expense/type', {projectCode: this.assignAndProjectObj.projectCode || e}).then(res => {
        if(res.data.code == 0) {
          let { data } = res;
          let expenseTypeOptions = {
              value: "expenseTypeId",
              optionsList: data.data,
            };

            this.setOPtionsList(this.formDetailList, expenseTypeOptions);
        }
      })
    },
    getAssignName() { // 获取任务书列表
      getApi('/project/assign/apply/all').then(res => {
        if(res.data.code == 0) {
          let { data } = res;
          let options = {
            value: 'assignName',
            optionsList: data.data,
          }

          let options1 = {
            value: 'assignNum',
            optionsList: data.data,
          }
          this.setOPtionsList(this.formBasicList, options1);
          this.setOPtionsList(this.formBasicList, options);
        }
      })
    },
    getAssignList(name) { // 获取任务书列表
      let params = {
        assignName: name,
      };
      postApi('/project/assign/apply/page/1/20',params).then(res => {
        if(res.data.code == 0) {
          let { data } = res;
          this.formBasicList.forEach(item=>{
            if(item.value == 'assignName' || item.value == 'assignNum'){
              item.optionsList = data.data.records
            }
          })
        }
      })
    },
    // changeAssignNum(e) {
    //   this.assignAndProjectObj = {};
    //   if(e) {
    //     this.formBasicList[3]?.optionsList?.forEach(item => {
    //       if(item.assignId == e) {
    //         this.assignAndProjectObj = item
    //       }
    //     })
    //     this.$emit('taskBookIdNum', this.assignAndProjectObj)
    //     this.$refs.expenseBasicForm.formData.assignId = this.assignAndProjectObj.assignId
    //     this.$refs.expenseBasicForm.formData.assignName = this.assignAndProjectObj.assignName
    //     this.$refs.expenseBasicForm.formData.assignNum = this.assignAndProjectObj.assignNum
    //     this.$refs.expenseBasicForm.formData.projectId = this.assignAndProjectObj.projectId
    //     this.$refs.expenseBasicForm.formData.projectCode = this.assignAndProjectObj.projectCode

    //     this.getExpenseType();
    //   }
    // },
    changeAssignNameAndNum(e) {
      this.assignAndProjectObj = {};
      if(e) {
        this.formBasicList[3]?.optionsList?.forEach(item => {
          if(item.assignId == e) {
            this.assignAndProjectObj = item
          }
        })
        this.$emit('taskBookIdNum', this.assignAndProjectObj)
        this.$refs.expenseBasicForm.formData.assignId = this.assignAndProjectObj.assignId
        this.$refs.expenseBasicForm.formData.assignName = this.assignAndProjectObj.assignName
        this.$refs.expenseBasicForm.formData.assignNum = this.assignAndProjectObj.assignNum
        this.$refs.expenseBasicForm.formData.projectId = this.assignAndProjectObj.projectId
        this.$refs.expenseBasicForm.formData.projectCode = this.assignAndProjectObj.projectCode

        this.getExpenseType();
      }
    },
    // 获取下拉选择数据
    getDataType() {
      // 获取项目列表数据
      getApi(`/project/prj/list/all`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "projectId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });
      // 获取验证人数据
      // postApi(`/admin/user/getver/list`, {
      //   userId: sessionStorage.getItem("userId"),
      // }).then((res) => {
      //   let { data } = res;
      //   if (data.code == 0) {
      //     let options = {
      //       value: "verifierId",
      //       optionsList: data.data,
      //     };
      //     this.setOPtionsList(this.formBasicList, options);
      //   }
      // });
    },
    //项目选择——剩余金额
    // changeProject(val) {
    //   this.residueAmountObj.projectId = val;
    //   this.getResidueAmount();
    // },
    // //费用类型——剩余金额
    // handleExpenseType(val) {
    //   this.residueAmountObj.expenseTypeId = val[0];
    //   this.getResidueAmount();
    // },
    // 查询剩余预算
    // getResidueAmount() {
    //   postApi(`/finance/applysheet/budget/surplus`, this.residueAmountObj)
    //     .then((res) => {
    //       this.$refs.expenseDetailForm.formData.residueAmount = parseFloat(res.data.data).toFixed(
    //         2
    //       );
    //     })
    //     .catch(() => {
    //       this.$refs.expenseDetailForm.formData.residueAmount = "0.00";
    //     });
    // },
    //编辑获取详情数据
    getDataDetail() {
      //编辑时，通过id获取数据
      let params = {
        id: this.expenseApply.id,
        type: "pc",
      };
      postApi(`/finance/applysheet/detail`, params).then((res) => {
        if (res.data.code == 0 && res.data.data) {
          if (res.data.data) {
            res.data.data.amount = parseFloat(res.data.data.amount).toFixed(2);
          }
          this.getAssignList(res.data.data.assignNum)
          let editDataSources = res.data.data;
          this.editDataSources = editDataSources;
          this.formBasicList.forEach((item) => {
            item.defaultValue = editDataSources[item.value];
          });
          
          this.formDetailList.forEach((item) => {
            item.defaultValue = editDataSources[item.value];
          });
          this.fileList = res.data.data.finExpenseApplyAttachFiles;
          this.fileListParam = res.data.data.finExpenseApplyAttachFiles;
          // this.residueAmountObj = {
          //   projectId: editDataSources.projectId,
          //   expenseTypeId: editDataSources.oneLevelId,
          // };
          // this.getResidueAmount();
          let obj = {
            assignId: editDataSources.assignId,
            assignNum: editDataSources.assignNum,
            assignName: editDataSources.assignName,
          }
          this.$emit('taskBookIdNum', obj)
          console.log('editDataSources', editDataSources)
          this.getExpenseType(editDataSources.prjCode)

        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //只能上传图片类型
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      const isLt5M = file.size / 1024 / 1024 < 5;

      if (!isJPG) {
        this.$message.error("上传图片只能是 jpg/jpeg/png格式!");
      }
      if (!isLt5M) {
        this.$message.error("上传附件图片大小不能超过 5MB!");
      }
      return isJPG && isLt5M;
    },
    //附件上传移除
    handleRemove(file, fileList) {
      this.fileList.map((item, index) => {
        if (item.name == file.name) {
          this.fileList.splice(index, 1);
        }
      });

      this.fileListParam = this.fileList;
    },
    //附件限制
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择10个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    // 附件上传成功回调
    beforeSuccess(response, file, fileList) {
      if (response.code == 0) {
        this.fileListParam = this.fileListParam.concat(response.data);
      }
    },
  },
};
</script>
<style lang="less" scoped></style>
