<template>
  <div class="container">
    <dd-card title="">
      <dd-form
        :form-config="formList"
        :labelWidth="'190px'"
        :flex="4"
        ref="form_addEditPopup"
        formRef="form_addEditPopup"
      ></dd-form>
    </dd-card>

    <dd-table
      ref="baseTable_1"
      :columns="tableColumns"
      :SetCellClassName="SetCellClassName"
      class="table_cell"
      isSort
      :summary-method="getSummaries"
      :data="tableData"
      show-summary
      border
      :isSelection="false"
      :loading="loading"
      max-height="560"
    >
    </dd-table>

    <dd-card title="">
      <dd-form
        :form-config="formList_remark"
        :labelWidth="'190px'"
        :flex="2"
        ref="plan_remark"
        formRef="plan_remark"
      ></dd-form>
    </dd-card>
  </div>
</template>

<script>
import { getApi, postApi } from "@/api/request";
export default {
  name: "detailPopup",
  props: ["pageProp"],
  data() {
    return {
      loading: false,
      saveBtn: false,
      tableColumns: [
        {
          title: "采购年度",
          key: "cfyear",
          align: "center",
          overflow: true,
        },
        {
          title: "采购合同编号",
          key: "fnumber",
          align: "center",
          overflow: true,
        },
        {
          title: "采购合同名称",
          key: "fname",
          align: "center",
          overflow: true,
        },
        {
          title: "乙方单位",
          key: "fpartbname",
          align: "center",
          overflow: true,
        },
        {
          title: "采购合同类型",
          key: "fcontracttypename",
          align: "center",
          overflow: true,
        },
        {
          title: "采购总金额",
          key: "foriginalamount",
          align: "center",
          overflow: true,
        },
        {
          title: "采购合同变更后总金额",
          key: "ftotalamount",
          align: "center",
          overflow: true,
        },
        {
          title: "采购累计结算金额",
          key: "fsettlebalance",
          align: "center",
          overflow: true,
        },
        {
          title: "采购累计实付金额",
          key: "frealamount",
          align: "center",
          overflow: true,
        },
        {
          title: "本期采购计划支付",
          key: "paymentAmount",
          align: "center",
          overflow: true,
        },
        {
          title: "本期采购计划付款节点说明",
          key: "nodeDescription",
          align: "center",
          overflow: true,
        },
        {
          title: "采购付款条件及说明",
          key: "conditionInstruction",
          align: "center",
          overflow: true,
        },
        {
          title: "备注",
          key: "remark",
          align: "center",
          overflow: true,
        },
        {
          title: "本期付款后采购累计已付",
          key: "currentPaymentAfterPaid",
          align: "center",
          overflow: true,
        },
        {
          title: "本期付款后采购剩余未付",
          key: "currentPaymentAfterUnpaid",
          align: "center",
          overflow: true,
        },
        {
          title: "本期实际付款金额",
          key: "actualAmount",
          align: "center",
          overflow: true,
        },
        {
          title: "关联项目编号",
          key: "prjCode",
          align: "center",
          overflow: true,
        },
        {
          title: "关联项目名称",
          key: "prjName",
          align: "center",
          overflow: true,
        },
        {
          title: "关联销售合同编号",
          key: "saleContractCode",
          align: "center",
          overflow: true,
        },
        {
          title: "关联销售合同名称",
          key: "saleContractName",
          align: "center",
          overflow: true,
        },
        {
          title: "销售合同额",
          key: "saleContractAmount",
          align: "center",
          overflow: true,
        },
        {
          title: "销售合同变更后合同额",
          key: "saleContractAfterModAmount",
          align: "center",
          overflow: true,
        },
        {
          title: "销售已回款",
          key: "saleContractRefund",
          align: "center",
          overflow: true,
        },
      ],
      tableData: [],
      formList: [
        {
          label: "单据编码",
          defaultValue: "",
          value: "code",
          disabled: true,
          inputType: "text",
          placeholderText: "请输入单据编码",
        },
        {
          label: "单据名称",
          defaultValue: "",
          value: "name",
          inputType: "text",
          disabled: true,
          placeholderText: "请输入单据名称",
        },

        {
          label: "单据状态",
          value: "statusName",
          disabled: true,
          inputType: "text",
          defaultValue: "",
          placeholderText: "请选择单据状态",
        },
        {
          label: "年度",
          value: "year",
          dateType: "year",
          format: "yyyy",
          displayFormat: "yyyy",
          defaultValue: "",
          inputType: "otherDate",
          disabled: true,
          placeholderText: "请选择年度",
        },
        {
          label: "月份",
          value: "month",
          defaultValue: "",
          format: "MM月",
          displayFormat: "MM月",
          dateType: "month",
          disabled: true,
          inputType: "otherDate",
          placeholderText: "请选择月份",
        },
        {
          label: "本期计划付款金额",
          value: "planAmount",
          inputType: "text",
          defaultValue: "",
          disabled: true,
          placeholderText: "请输入本期计划付款金额",
        },
        {
          label: "本期实际付款金额",
          value: "actualAmount",
          inputType: "text",
          defaultValue: "",
          disabled: true,
          placeholderText: "本期实际付款金额",
        },
        {
          label: "执行周期",
          value: "executeDuration",
          inputType: "text",
          defaultValue: "",
          disabled: true,
          placeholderText: "执行周期",
        },
      ],
      formList_remark: [
        {
          label: "付款备注",
          value: "remark",
          inputType: "text",
          type: "textarea",
          defaultValue: "",
          show: true,
          disabled: true,
          placeholderText: "请输入付款备注",
        },
        {
          label: "审批意见",
          value: "approval",
          inputType: "text",
          type: "textarea",
          defaultValue: "",
          show: true,
          disabled: true,
          placeholderText: "请输入审批意见",
        },
      ],
    };
  },
  created() {
    this.getPageData();
  },
  mounted() {},
  methods: {
    SetCellClassName({ row, column, rowIndex, columnIndex }) {
      if (row.approve == 0) {
        return "backgroundColor";
      }
    },
    getPageData() {
      getApi(`/contract/execute?id=${this.pageProp.id}`).then((res) => {
        if (res.data.code == 0) {
          let { data } = res;
          data.data.year = data.data.year.toString();
          this.$refs.form_addEditPopup.formData = { ...data.data };
          this.$refs.plan_remark.formData.remark = data.data.remark;
          this.$refs.plan_remark.formData.approval = data.data.approval;
          this.tableData = data.data.scheduleVOS || [];
          let sum = 0;
          this.tableData.forEach((item) => {
            sum += Number(item.actualAmount) || 0;
          });
          this.$refs.form_addEditPopup.formData.actualAmount = sum.toFixed(2);
        }
      });
    },
    getSummaries(param) {
      // 自定义项表格合计方法
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (
          column.property == "paymentAmount" ||
          column.property == "actualAmount"
        ) {
          //如果是经费（正常的加减法）
          const values = data.map((item) =>
            item[column.property] ? item[column.property] * 1 : 0
          );
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              var sum = 0;
              if (!isNaN(value)) {
                sum = Number(Number(prev) + Number(curr)).toFixed(2);
                return sum;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += " ";
          }
        }
      });
      return sums;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
  resize: none;
}
/deep/ .table_cell {
  .backgroundColor {
    background-color: #f5d7c8 !important;
  }
}
/deep/ .el-range-editor.is-disabled {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/ .el-range-editor.is-disabled input {
  color: @defaultFontColor;
}
</style>