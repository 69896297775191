<template>
  <div class="container">
    <dd-table
      :columns="tableColumns_record"
      :data="tableData_record"
      show-summary
      :summary-method="getRecordSummaries"
      border
      :isSelection="false"
      :loading="loading"
      max-height="700"
    >
    </dd-table>

    <dd-popup
      v-if="addEditPopupVisible"
      width="50%"
      height="65%"
      inPopup
      showBtn
      :cancelBtn="true"
      :saveBtn="record.pageType == 'edit'"
      :submitBtn="false"
      :show="addEditPopupVisible"
      :title-name="addEditPopupTitle"
      @popup-save="handleSave"
      @popup-close="handleClose"
    >
      <addEditPopup
        ref="addEditPopup"
        :record="record"
        slot="main"
      ></addEditPopup>
    </dd-popup>
  </div>
</template>

<script>
import { getApi, putApi } from "@/api/request";
import addEditPopup from "./addEditPopup.vue";
export default {
  name: "contractDetail",
  props: ["pageProp"],
  components: { addEditPopup },
  data() {
    return {
      activeName: "相关记录详情",
      detailVisible: false,
      pageProp: {},
      loading: false,
      tableColumns_record: [
        {
          title: "单据编号",
          key: "code",
          align: "center",
          overflow: true,
          width: 120
        },
        {
          title: "销售合同编号",
          key: "saleContractCode",
          align: "center",
          overflow: true,
          width: 120
        },
        {
          title: "销售合同名称",
          key: "saleContractName",
          align: "center",
          overflow: true,
          width: 120
        },
        {
          title: "甲方",
          key: "partAId",
          align: "center",
          overflow: true,
          width: 120
        },
        {
          title: "已用金额",
          key: "amount",
          align: "center",
          overflow: true,
          width: 120
        },
        {
          title: "备注",
          key: "remark",
          align: "center",
          overflow: true,
          width: 120
        },
        {
          title: "自建采购合同编号",
          key: "selfContractCode",
          align: "center",
          overflow: true,
        },
        {
          title: "自建采购合同名称",
          key: "selfContractName",
          align: "center",
          overflow: true,
        },
        {
          title: "金蝶合同编号",
          key: "kingContractCode",
          align: "center",
          overflow: true,
          width: 120
        },
        {
          title: "金蝶合同名称",
          key: "kingContractName",
          align: "center",
          overflow: true,
          width: 120
        },
        {
          title: "金蝶合同甲方",
          key: "kingContractPartA",
          align: "center",
          overflow: true,
          width: 120
        },
        {
          title: "记录日期",
          key: "recordDate",
          align: "center",
          overflow: true,
          width: 120
        },
        {
          title: "最新修改日期",
          key: "modifyDate",
          align: "center",
          overflow: true,
          width: 120
        },
        {
          title: "操作",
          align: "center",
          fixed: "right",
          overflow: true,
          width: 200,
          event: [
            {
              state: "default",
              label: "修改",
              type: "warning",
              width: 100,
              method: (row) => {
                this.handleEdit(row);
              },
            },
            {
              state: "default",
              label: "查看",
              width: 100,
              method: (row) => {
                this.handleRowRecordDetail(row);
              },
            },
          ],
        },
      ],
      tableData: [],
      tableData_record: [],
      addEditPopupTitle: "",
      addEditPopupVisible: false,
      record: {},
    };
  },
  created() {
    this.getRecordData();
  },
  mounted() {},
  methods: {
    handleSave() {
      let flag = false;
      this.$refs.addEditPopup.$refs.addPopForm.$refs.addPopForm.validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      if (!flag) {
        return false;
      }
      let param = {
        id: this.record.id,
        amount: this.$refs.addEditPopup.$refs.addPopForm.formData.amount,
      };

      putApi(`/contract/specialrecord`, param).then((res) => {
        if (res.data.code == 0) {
          this.$message.success("修改成功");
        } else {
          this.$message.success("修改失败，请稍后重试");
        }
        this.addEditPopupVisible = false;
        this.getRecordData();
      });
    },
    handleEdit(row) {
      this.addEditPopupTitle = "编辑";
      this.addEditPopupVisible = true;
      this.record = row;
      this.record.pageType = "edit";
    },
    handleClose() {
      this.detailVisible = false;
      this.addEditPopupVisible = false;
    },
    handleClick(e) {},
    handleRowRecordDetail(row) {
      this.addEditPopupTitle = "查看";
      this.addEditPopupVisible = true;
      this.record = row;
      this.record.pageType = "detail";
    },
    handleRowDetail(row) {
      this.detailVisible = true;
      this.pageProp.id = row.purchaseContractId;
    },

    getRecordData() {
      getApi(`/contract/specialrecord/sale/list`, {
        id: this.pageProp.id,
      }).then((res) => {
        if (res.data.code == 0) {
          this.tableData_record = res.data.data;
        }
      });
    },
    getRecordSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (column.property == "amount") {
          //如果是经费（正常的加减法）
          const values = data.map((item) =>
            item[column.property] ? item[column.property] * 1 : 0
          );
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              var sum = 0;
              if (!isNaN(value)) {
                sum = Number(Number(prev) + Number(curr)).toFixed(2);
                return sum;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += " ";
          }
        }
      });
      return sums;
    },
  },
};
</script>

<style lang="less" scoped>
</style>