<template>
  <div class="container">
    <dd-card title="">
      <dd-form
        :form-config="formList"
        :labelWidth="'190px'"
        :flex="3"
        ref="form_addEditPopup"
        formRef="form_addEditPopup"
      ></dd-form>
    </dd-card>

    <dd-table
      ref="baseTable_1"
      :columns="tableColumns"
      :SetCellClassName="SetCellClassName"
      class="table_cell"
      isSort
      :summary-method="getSummaries"
      :data="tableData"
      show-summary
      border
      :isSelection="false"
      :loading="loading"
      max-height="560"
    >
    </dd-table>

    <dd-card title="">
      <dd-form
        :form-config="formList_remark"
        :labelWidth="'190px'"
        :flex="2"
        ref="plan_remark"
        formRef="plan_remark"
      ></dd-form>
    </dd-card>
  </div>
</template>

<script>
import { getApi, postApi, putApi } from "@/api/request";
export default {
  name: "addEditPopup",
  props: ["pageProp"],
  components: {},
  data() {
    return {
      rowData: {},
      popup_formVisible: false,
      loading: false,
      addKingDeeVisible: false,
      rowId: null,
      saveBtn: false,
      tableColumns: [
        {
          title: "采购年度",
          key: "cfyear",
          align: "center",
          overflow: true,
        },
        {
          title: "采购合同编号",
          key: "fnumber",
          align: "center",
          overflow: true,
          width: this.pageProp.pageType !== "detail" ? 120 : "auto",
        },
        {
          title: "采购合同名称",
          key: "fname",
          align: "center",
          overflow: true,
          width: this.pageProp.pageType !== "detail" ? 120 : "auto",
        },
        {
          title: "乙方单位",
          key: "fpartbname",
          align: "center",
          overflow: true,
        },
        {
          title: "采购合同类型",
          key: "fcontracttypename",
          align: "center",
          overflow: true,
          width: this.pageProp.pageType !== "detail" ? 120 : "auto",
        },
        {
          title: "采购总金额",
          key: "foriginalamount",
          align: "center",
          overflow: true,
          width: this.pageProp.pageType !== "detail" ? 120 : "auto",
        },
        {
          title: "采购合同变更后总金额",
          key: "ftotalamount",
          align: "center",
          overflow: true,
          width: this.pageProp.pageType !== "detail" ? 170 : "auto",
        },
        {
          title: "采购累计结算金额",
          key: "fsettlebalance",
          align: "center",
          overflow: true,
          width: this.pageProp.pageType !== "detail" ? 150 : "auto",
        },
        {
          title: "采购累计实付金额",
          key: "frealamount",
          align: "center",
          overflow: true,
          width: this.pageProp.pageType !== "detail" ? 150 : "auto",
        },
        {
          title: "本期采购计划支付",
          key: "paymentAmount",
          align: "center",
          overflow: true,
          width: this.pageProp.pageType !== "detail" ? 150 : "auto",
        },
        {
          title: "本期采购计划付款节点说明",
          key: "nodeDescription",
          align: "center",
          overflow: true,
          width: this.pageProp.pageType !== "detail" ? 190 : "auto",
        },
        {
          title: "采购付款条件及说明",
          key: "conditionInstruction",
          align: "center",
          overflow: true,
          width: this.pageProp.pageType !== "detail" ? 150 : "auto",
        },
        {
          title: "备注",
          key: "remark",
          align: "center",
          overflow: true,
          width: this.pageProp.pageType !== "detail" ? 150 : "auto",
        },
        {
          title: "本期付款后采购累计已付",
          key: "currentPaymentAfterPaid",
          align: "center",
          overflow: true,
          width: this.pageProp.pageType !== "detail" ? 180 : "auto",
        },
        {
          title: "本期付款后采购剩余未付",
          key: "currentPaymentAfterUnpaid",
          align: "center",
          overflow: true,
          width: this.pageProp.pageType !== "detail" ? 180 : "auto",
        },
        {
          title: "关联项目编号",
          key: "prjCode",
          align: "center",
          overflow: true,
          width: this.pageProp.pageType !== "detail" ? 120 : "auto",
        },
        {
          title: "关联项目名称",
          key: "prjName",
          align: "center",
          overflow: true,
          width: this.pageProp.pageType !== "detail" ? 120 : "auto",
        },
        {
          title: "关联销售合同编号",
          key: "saleContractCode",
          align: "center",
          overflow: true,
          width: this.pageProp.pageType !== "detail" ? 150 : "auto",
        },
        {
          title: "关联销售合同名称",
          key: "saleContractName",
          align: "center",
          overflow: true,
          width: this.pageProp.pageType !== "detail" ? 150 : "auto",
        },
        {
          title: "销售合同额",
          key: "saleContractAmount",
          align: "center",
          overflow: true,
          width: this.pageProp.pageType !== "detail" ? 120 : "auto",
        },
        {
          title: "销售合同变更后合同额",
          key: "saleContractAfterModAmount",
          align: "center",
          overflow: true,
          width: this.pageProp.pageType !== "detail" ? 180 : "auto",
        },
        {
          title: "销售已回款",
          key: "saleContractRefund",
          align: "center",
          overflow: true,
          width: this.pageProp.pageType !== "detail" ? 120 : "auto",
        },
        {
          title: "操作",
          key: "code",
          align: "center",
          fixed: "right",
          overflow: true,
          width: 140,
          event: [
            {
              state: "default",
              label: "同意",
              type: "success",
              method: (row) => {
                this.handleApprove(row, 1);
              },
              rules: (row) => {
                let show = true;
                if (row.approve == 1) {
                  show = false;
                }
                return show;
              },
            },
            {
              state: "default",
              label: "拒绝",
              type: "danger",
              method: (row) => {
                this.handleApprove(row, 0);
              },
              rules: (row) => {
                let show = true;
                if (row.approve == 0) {
                  show = false;
                }
                return show;
              },
            },
          ],
        },
      ],
      tableData: [],
      formList: [
        {
          label: "单据编码",
          defaultValue: "",
          value: "code",
          disabled: true,
          inputType: "text",
          placeholderText: "请输入单据编码",
        },
        {
          label: "单据名称",
          defaultValue: "",
          value: "name",
          inputType: "text",
          disabled: true,
          placeholderText: "请输入单据名称",
        },

        {
          label: "单据状态",
          value: "status",
          disabled: true,
          inputType: "select",
          optionsList: [],
          filterable: true,
          defaultValue: "",
          placeholderText: "请选择单据状态",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
        },
        {
          label: "年度",
          value: "year",
          dateType: "year",
          format: "yyyy",
          displayFormat: "yyyy",
          defaultValue: "",
          inputType: "otherDate",
          disabled: this.pageProp.pageType != "add",
          placeholderText: "请选择年度",
          rules: [
            {
              required: true,
              message: "请选择年度",
              trigger: "blur",
            },
          ],
        },
        {
          label: "月份",
          value: "month",
          defaultValue: "",
          format: "MM月",
          displayFormat: "MM月",
          dateType: "month",
          disabled: this.pageProp.pageType != "add",
          inputType: "otherDate",
          placeholderText: "请选择月份",
          rules: [
            {
              required: true,
              message: "请选择月份",
              trigger: "blur",
            },
          ],
        },
        {
          label: "本期计划付款金额",
          value: "amount",
          inputType: "text",
          defaultValue: "",
          disabled: true,
          placeholderText: "请输入本期计划付款金额",
        },
      ],
      formList_remark: [
        {
          label: "付款备注",
          value: "remark",
          inputType: "text",
          type: "textarea",
          defaultValue: "",
          show: true,
          disabled:
            this.pageProp.pageType != "add" || this.pageProp.pageType != "edit",
          placeholderText: "请输入付款备注",
        },
        {
          label: "审批意见",
          value: "approval",
          inputType: "text",
          type: "textarea",
          defaultValue: "",
          show: true,
          disabled: this.pageProp.pageType != "check",
          placeholderText: "请输入审批意见",
        },
      ],
    };
  },
  created() {
    this.getPageData();
    this.getDataType();
  },
  mounted() {
    if (this.pageProp.pageType == "detail") {
      this.tableColumns.pop();
    }
  },
  methods: {
    SetCellClassName({ row, column, rowIndex, columnIndex }) {
      if (row.approve == 0) {
        return "backgroundColor";
      }
    },
    handleApprove(row, type) {
      let param = {
        approve: type,
        id: row.id,
      };
      putApi(`/contract/scheduledetail`, param).then((res) => {
        if (res.data.code == 0) {
          this.$message.success("审批成功");
        } else {
          this.$message.warning("审批失败");
        }
        this.getPageData();
      });
    },
    getPageData() {
      getApi(`/contract/payment/schedule/query?id=${this.pageProp.id}`).then(
        (res) => {
          if (res.data.code == 0) {
            let { data } = res;
            data.data.year = data.data.year.toString();
            this.$refs.form_addEditPopup.formData = { ...data.data };
            this.$refs.plan_remark.formData.remark = data.data.remark;
            this.$refs.plan_remark.formData.approval = data.data.approval;
            this.tableData = data.data.scheduleVOS || [];
          }
        }
      );
    },
    handleClose() {
      this.addKingDeeVisible = false;
      this.popup_formVisible = false;
    },
    getDataType() {
      postApi(`/contract/dicttype/list/all`, { code: "PC-CGJH-STATUS" }).then(
        (res) => {
          if (res.data.code == 0) {
            this.formList.forEach((item) => {
              if (item.value == "status") {
                item.optionsList = res.data.data;
              }
            });
          }
        }
      );
    },
    getSummaries(param) {
      // 自定义项表格合计方法
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (column.property == "paymentAmount") {
          //如果是经费（正常的加减法）
          const values = data.map((item) =>
            item[column.property] ? item[column.property] * 1 : 0
          );
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              var sum = 0;
              if (!isNaN(value)) {
                sum = Number(Number(prev) + Number(curr)).toFixed(2);
                return sum;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += " ";
          }
        }
      });
      return sums;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
  resize: none;
}
/deep/ .table_cell {
  .backgroundColor {
    background-color: #f5d7c8 !important;
  }
}
/deep/ .el-range-editor.is-disabled {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/ .el-range-editor.is-disabled input {
  color: @defaultFontColor;
}
</style>