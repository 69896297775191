<template>
  <div class="main-container">
    <dd-form
      :form-config="formBasicList"
      ref="addPopForm"
      formRef="addPopForm"
      :labelWidth="'140px'"
      :flex="1"
    >
    </dd-form>
  </div>
</template>

<script>
import { postApi, getApi, putApi } from "@/api/request";
import { getNowDate, getNowYear } from "@/utils/date";
export default {
  name: "add-pop",
  props: ["pageProp"],
  data() {
    return {
      formBasicList: [
        {
          label: "付款计划申请单",
          value: "planApplyId",
          inputType: "select",
          defaultValue: "",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择付款计划申请单",
          optionsListConfig: {
            name: "name",
            code: "id",
          },
          rules: [
            {
              required: true,
              message: "请选择付款计划申请单",
              trigger: "blur",
            },
          ],
        },
        {
          label: "执行周期",
          value: "executeDuration",
          placeholderText: "执行周期",
          inputType: "date",
          dateType: "daterange",
          defaultValue: '',
          format: "yyyy-MM-dd",
          rules: [
            {
              type: "array",
              required: true,
              message: "请选择执行周期",
              trigger: "blur",
            },
          ],
        },
      ],
      detailData: null,
    };
  },
  created() {},
  mounted() {
    this.getDataType();
    if (this.pageProp.pageType == "edit") {
      this.formBasicList[0].defaultValue = this.pageProp.planApplyId;
      this.formBasicList[1].defaultValue = [
        this.pageProp.startTime + ' 00:00:00',
        this.pageProp.endTime + ' 00:00:00',
      ];
    }
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    getDataType() {
      let params = {
        executeflag: "1",
      };
      postApi(`/contract/payment/schedule/page/1/999999`, params).then(
        (res) => {
          let { data } = res;
          if (data.code == 0) {
            let options = {
              value: "planApplyId",
              optionsList: data.data.records,
            };
            this.setOPtionsList(this.formBasicList, options);
          }
        }
      );
    },
  },
};
</script>
<style lang="less" scoped>
</style>
