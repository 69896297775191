<template>
  <div class="container">
    <dd-card title="基本信息">
      <dd-form
        :form-config="formList"
        :labelWidth="'170px'"
        :flex="3"
        ref="assignForm"
        formRef="assignForm"
      ></dd-form>
    </dd-card>
    <dd-card title="生产预算自定义项">
      <div class="buttonBox">
        <dd-button size="medium" icon="add" @click="addWork()"
          >添加工作内容</dd-button
        >
        <dd-button size="medium" icon="delete" @click="delWork()"
          >删除工作内容</dd-button
        >
        <div class="explain">说明：预算输入框数据为红色字体时，所属类型不符合预算基准单标准费用</div>
      </div>
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane
          v-for="(item, idx) in tabArr"
          :key="idx"
          :label="item.name"
          :name="item.name"
        >
          
        </el-tab-pane>
      </el-tabs>
      <dd-table
            :columns="tableColumns"
            ref="multipleTable"
            show-summary
            class="table_cell"
            :SetCellClassName="SetCellClassName"
            :summary-method="getSummaries"
            :data="tableDataSource"
            :topBtn="false"
            :is-selection="true"
            @select-change="handleSelectionChange"
            :loading="loading"
            border
          >
          </dd-table>
    </dd-card>

    <dd-card title="生产预算固定项">
      <dd-table
        :columns="tableColumnsFixed"
        :data="tableDataFixed"
        show-summary
        :summary-method="getSummariesFixed"
        :topBtn="false"
        :is-selection="false"
        :loading="loading"
        border
      >
      </dd-table>
    </dd-card>
    <!-- <dd-card title="审批流程" v-if="addEdit.showStep">
      <el-steps
        :active="activeIndex"
        align-center
        :finish-status="
        activeIndex == activityList.length ? 'success' : 'finish'
        "
      >
        <el-step
          v-for="(val, idx) in activityList"
          :key="idx"
          :title="val.activityName"
        >
          <div slot="description" class="description">
            <div
              v-for="(item, index) in val.description"
              :key="index"
              class="list"
            >
              <div class="">
                {{ item.time }}
                <div class="name">{{ item.userName }} {{ item.message }}</div>
              </div>
              <div>意见 {{ item.fullmessage }}</div>
            </div>
          </div>
        </el-step>
      </el-steps>
    </dd-card> -->
    <dd-card title="审批流程" v-if="addEdit.showStep">
      <dd-table
        ref="expenseDetail"
        :topBtn="false"
        :isSelection="false"
        :isSort="true"
        :columns="tableColumnsStep"
        :data="tableDataSources"
        border
      ></dd-table>
    </dd-card>

    <dd-card title="">
      <dd-form
        :form-config="PersonList"
        :labelWidth="'170px'"
        :flex="3"
        ref="PersonForm"
        formRef="PersonForm"
      ></dd-form>
    </dd-card>
  </div>
</template>

<script>
import { postApi, getApi, loadApi } from "@/api/request";
import { downloadBinaryFile } from "@/utils/index";
import { mapState, mapActions } from "vuex";

export default {
  name: "add",
  props: {
    type: "",
    addEdit: {},
  },
  // props: ["type", "id", "state"],
  computed: {
    // ...mapState(["btnAuthorityList"]),
  },
  data() {
    return {
      activityList: [],
      description: [],
      tableDataSources: [],
      tableColumnsStep: [
        {
          title: "节点名称",
          key: "taskName",
          align: "center",
        },
        {
          title: "操作时间",
          key: "time",
          align: "center",
        },
        {
          title: "节点操作人",
          key: "userName",
          align: "center",
        },
        {
          title: "审批意见",
          key: "fullmessage",
          align: "center",
        },
      ],
      activeIndex: 0,
      loading: false,
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("authToken") || "",
      }, // 请求头信息
      activeName: "显示全部",
      sumExpenseSubtotalZdy: 0,
      sumExpenseSubtotalGd: 0,
      tableDataFixed: [{jobContent: '检查项', workType: 'BUDGET-WORK-TYPE-JC'}, {jobContent: '管理项', workType: 'BUDGET-WORK-TYPE-GL'}],
      tableDataSource: [{
        index: 1,
      }],
      selectTableSource: [],
      PersonList: [
        // 底部编制人 表单
        {
          label: "编制人",
          value: "applierName",
          inputType: "text",
          placeholderText: "",
          disabled: true,
        },
        {
          label: "编制时间",
          value: "applyDate",
          inputType: "date",
          format: "yyyy-MM-dd",
          dateType: "date",
          defaultValue: '',
          disabled: true,
        },
      ],
      tableColumnsFixed: [
        // 固定项表头
        {
          title: "工作内容",
          key: "jobContent",
          align: "center",
        },
        {
          title: "工种",
          key: "workType",
          align: "center",
          width: 160,
          selectInput: {
            placeholderText: "请选择工种",
            options: [],
            isEdit: () => {
              let state = false;
              return true;
            },
          },
        },
        {
          title: "项目所需标准工天(不含外协)",
          key: "standardWorkday",
          align: "center",
          width: 220,
          input: [
            {
              method: (value, row, index) => {
                this.changeAmountFixed(value, row, index);
              },
              isEdit: () => {
                // if (this.addEdit.type == "detail") {
                //   state = true;
                // }
                return true;
              },
              rules: (value) => {
                        let result = value.replace(/[^\d.]/g, '');
                        if (result.charAt(0) === '.') {
                          result = result.slice(1);
                        }
                        const parts = result.split('.');
                        if (parts.length > 2) {
                          result = parts.slice(0, 2).join('.');
                        }
                        if (parts.length > 1) {
                          result = `${parts[0]}.${parts[1].slice(0, 2)}`;
                        }
                        return result;
                      },
            }
          ]
        },
        {
          title: "定额百分比",
          key: "quotaPercentage",
          align: "center",
          isCustom: {
            isTrue: true,
            options1: ()=> {
              return this.optionsJC;
            },
            options2: () => {
              return this.optionsGL;
            },
          },
          width: 170,
          change: (value, row)=> {
            this.changeQuotaPercentage(value, row);
          },
          selectInput: {
            placeholderText: "请选择定额百分比",
            isEdit: () => {
              let state = false;
              if (this.addEdit.type == "detail") {
                  state = true;
                }
              return state;
            },
          },
        },
        {
          title: "工天",
          key: "workday",
          align: "center",
        },
        {
          title: "费用小计",
          key: "expenseSubtotal",
          align: "center",
        },
        {
          title: "绩效工资",
          align: "center",
          width: 180,
          children: [
            {
              title: "单价",
              key: "meritPayUnivalence",
              align: "center",
              input: [
                {
                  method: (value, row, index) => {
                    this.changeAmountFixed(value, row, index);
                  },
                  isEdit: () => {
                    let state = false;
                    if (this.addEdit.type == "detail") {
                      state = true;
                    }
                    return state;
                  },
                  rules: (value) => {
                        let result = value.replace(/[^\d.]/g, '');
                        if (result.charAt(0) === '.') {
                          result = result.slice(1);
                        }
                        const parts = result.split('.');
                        if (parts.length > 2) {
                          result = parts.slice(0, 2).join('.');
                        }
                        if (parts.length > 1) {
                          result = `${parts[0]}.${parts[1].slice(0, 2)}`;
                        }
                        return result;
                      },
                },
              ],
            },
            {
              title: "费用",
              key: "meritPayExpense",
              align: "center",
            },
          ],
        },
        {
          title: "基本工资+社保",
          align: "center",
          width: 180,
          children: [
            {
              title: "单价",
              key: "salaryInsuranceUnivalence",
              align: "center",
              input: [
                {
                  method: (value, row, index) => {
                    this.changeAmountFixed(value, row, index);
                  },
                  isEdit: () => {
                    let state = false;
                    if (this.addEdit.type == "detail") {
                      state = true;
                    }
                    return state;
                  },
                  rules: (value) => {
                        let result = value.replace(/[^\d.]/g, '');
                        if (result.charAt(0) === '.') {
                          result = result.slice(1);
                        }
                        const parts = result.split('.');
                        if (parts.length > 2) {
                          result = parts.slice(0, 2).join('.');
                        }
                        if (parts.length > 1) {
                          result = `${parts[0]}.${parts[1].slice(0, 2)}`;
                        }
                        return result;
                      },
                },
              ],
            },
            {
              title: "费用",
              key: "salaryInsuranceExpense",
              align: "center",
            },
          ],
        },
      ],
      formList: [
        // 基本信息表单配置项
        {
          label: "生产任务书名称",
          value: "assignName",
          inputType: "select",
          optionsList: [],
          filterable: "filterable",
          placeholderText: "请选择生产任务书名称",
          optionsListConfig: {
            name: "assignName",
            code: "assignId",
          },
          disabled: this.addEdit.type == "detail" || this.addEdit.jbxxDisabled,
          change: this.changeAssignName,
          rules: [
              {
                required: true,
                message: "请选择生产任务书名称",
                trigger: "blur",
              },
            ],
        },
        {
          label: "生产任务书编码",
          value: "assignNum",
          inputType: "text",
          placeholderText: "",
          disabled: true,
        },
        {
          label: "生产预算总额",
          value: "budgetTotalAmount",
          inputType: "text",
          disabled: true,
        },
        {
          label: "销售合同名称",
          value: "contractName",
          inputType: "text",
          disabled: true,
        },
        {
          label: "销售合同编码",
          value: "contractCode",
          inputType: "text",
          disabled: true,
        },
        {
          label: "暂定合同额",
          value: "assignAmount",
          inputType: "text",
          disabled: true,
        },
        {
          label: "版本",
          value: "version",
          inputType: "text",
          disabled: true,
        },
        {
          label: "状态",
          value: "statusName",
          inputType: "text",
          disabled: true,
        },
      ],
      optionsGL: [],
      optionsJC: [],
      tableColumns: [], // 自定义项表头
      ColumnsConfig: [
        // 自定义项表头无需切换部分配置
        {
          title: "序号",
          key: "index",
          align: "center",
        },
        {
          title: "工作内容",
          key: "jobContent",
          align: "center",
          width: 180,
          input: [
            {
              placeholderText: "请输入工作内容",
              isEdit: () => {
                let state = false;
                if (this.addEdit.type == "detail") {
                  state = true;
                }
                return state;
              },
            },
          ],
        },
        {
          title: "计量单位",
          key: "measureUnit",
          align: "center",
          filterable: true,
          width: 140,
          selectInput: {
            placeholderText: "请选择计量单位",
            options: [],
            isEdit: () => {
                let state = false;
                if (this.addEdit.type == "detail") {
                  state = true;
                }
                return state;
              },
          },
        },
        {
          title: "总工作量",
          key: "totalEffort",
          width: 120,
          align: "center",
          input: [
            {
              method: (value, row, index) => {
                this.changeAmount(value, row, index);
              },
              isEdit: () => {
                let state = false;
                if (this.addEdit.type == "detail") {
                  state = true;
                }
                return state;
              },
              rules: (value) => {
                let result = value.replace(/[^\d.]/g, '');
                if (result.charAt(0) === '.') {
                  result = result.slice(1);
                }
                const parts = result.split('.');
                if (parts.length > 2) {
                  result = parts.slice(0, 2).join('.');
                }
                if (parts.length > 1) {
                  result = `${parts[0]}.${parts[1].slice(0, 2)}`;
                }
                return result;
              },
            },
          ],
        },
        {
          title: "工种",
          key: "workType",
          align: "center",
          width: 140,
          selectInput: {
            placeholderText: "请选择工种",
            options: [],
            
            isEdit: () => {
                let state = false;
                if (this.addEdit.type == "detail") {
                  state = true;
                }
                return state;
              },
          },
          change: (val, row) => {
              this.changeWorkType(val, row);
            },
        },
        {
          title: "效率",
          key: "efficiency",
          align: "center",
          input: [
            {
              method: (value, row, index) => {
                this.changeAmount(value, row, index);
              },
              isEdit: () => {
                let state = false;
                if (this.addEdit.type == "detail") {
                  state = true;
                }
                return state;
              },
              rules: (value) => {
                let result = value.replace(/[^\d.]/g, '');
                if (result.charAt(0) === '.') {
                  result = result.slice(1);
                }
                const parts = result.split('.');
                if (parts.length > 2) {
                  result = parts.slice(0, 2).join('.');
                }
                if (parts.length > 1) {
                  result = `${parts[0]}.${parts[1].slice(0, 4)}`;
                }
                return result;
              },
            },
          ],
        },
        {
          title: "工天",
          key: "workday",
          align: "center",
        },
        {
          title: "费用小计",
          key: "expenseSubtotal",
          align: "center",
        },
      ],
      ColumnsAllocation: [
        {
          type: "材料办公",
          content: [
            {
              title: "材料办公",
              align: "center",
              children: [
                {
                  title: "单价",
                  key: "materialsOfficeUnivalence",
                  align: "center",
                  input: [
                    {
                      isEdit: () => {
                        let state = false;
                        if (this.addEdit.type == "detail") {
                          state = true;
                        }
                        return state;
                      },
                      rules: (value) => {
                        let result = value.replace(/[^\d.]/g, '');
                        if (result.charAt(0) === '.') {
                          result = result.slice(1);
                        }
                        const parts = result.split('.');
                        if (parts.length > 2) {
                          result = parts.slice(0, 2).join('.');
                        }
                        if (parts.length > 1) {
                          result = `${parts[0]}.${parts[1].slice(0, 2)}`;
                        }
                        return result;
                      },
                    },
                  ],
                },
                {
                  title: "费用",
                  key: "materialsOfficeExpense",
                  align: "center",
                  input: [
                    {
                      isEdit: () => {
                        let state = false;
                        if (this.addEdit.type == "detail") {
                          state = true;
                        }
                        return state;
                      },
                      method: (value, row, index) => {
                        this.changeAmount(value, row, index);
                      },
                      rules: (value) => {
                        let result = value.replace(/[^\d.]/g, '');
                        if (result.charAt(0) === '.') {
                          result = result.slice(1);
                        }
                        const parts = result.split('.');
                        if (parts.length > 2) {
                          result = parts.slice(0, 2).join('.');
                        }
                        if (parts.length > 1) {
                          result = `${parts[0]}.${parts[1].slice(0, 2)}`;
                        }
                        return result;
                      },
                    },
                  ],
                },
                {
                  title: "备注",
                  inputTooltip: true,
                  key: "materialsOfficeRemark",
                  align: "center",
                  input: [
                    {
                      isEdit: () => {
                        let state = false;
                        if (this.addEdit.type == "detail") {
                          state = true;
                        }
                        return state;
                      },
                    },
                  ],
                }
              ],
            },
          ],
        },
        {
          type: "厨具伙食费用",
          content: [
            {
              // 厨具伙食费用表头数据
              title: "厨具伙食费用",
              align: "center",
              children: [
                {
                  title: "单价",
                  key: "cookingUtensilsUnivalence",
                  align: "center",
                  input: [
                    {
                      isEdit: () => {
                        let state = false;
                        if (this.addEdit.type == "detail") {
                          state = true;
                        }
                        return state;
                      },
                      method: (value, row, index) => {
                        this.changeAmount(value, row, index);
                      },
                      rules: (value) => {
                        let result = value.replace(/[^\d.]/g, '');
                        if (result.charAt(0) === '.') {
                          result = result.slice(1);
                        }
                        const parts = result.split('.');
                        if (parts.length > 2) {
                          result = parts.slice(0, 2).join('.');
                        }
                        if (parts.length > 1) {
                          result = `${parts[0]}.${parts[1].slice(0, 2)}`;
                        }
                        return result;
                      },
                    },
                  ],
                },
                {
                  title: "费用",
                  key: "cookingUtensilsExpense",
                  align: "center",
                },
                {
                  title: "备注",
                  inputTooltip: true,
                  key: "cookingUtensilsRemark",
                  align: "center",
                  input: [
                    {
                      isEdit: () => {
                        let state = false;
                        if (this.addEdit.type == "detail") {
                          state = true;
                        }
                        return state;
                      },
                    },
                  ],
                },

              ],
            },
          ],
        },
        {
          type: "车辆租赁费用",
          content: [
            {
              // 车辆租赁费表头数据
              title: "车辆租赁费用",
              align: "center",
              children: [
                {
                  title: "单价",
                  key: "vehicleRentalUnivalence",
                  align: "center",
                  input: [
                    {
                      isEdit: () => {
                        let state = false;
                        if (this.addEdit.type == "detail") {
                          state = true;
                        }
                        return state;
                      },
                      method: (value, row, index) => {
                        this.changeAmount(value, row, index);
                      },
                      rules: (value) => {
                        let result = value.replace(/[^\d.]/g, '');
                        if (result.charAt(0) === '.') {
                          result = result.slice(1);
                        }
                        const parts = result.split('.');
                        if (parts.length > 2) {
                          result = parts.slice(0, 2).join('.');
                        }
                        if (parts.length > 1) {
                          result = `${parts[0]}.${parts[1].slice(0, 2)}`;
                        }
                        return result;
                      },
                    },

                  ],
                },
                {
                  title: "费用",
                  key: "vehicleRentalExpense",
                  align: "center",
                },
                {
                  title: "备注",
                  inputTooltip: true,
                  key: "vehicleRentalRemark",
                  align: "center",
                  input: [
                    {
                      isEdit: () => {
                        let state = false;
                        if (this.addEdit.type == "detail") {
                          state = true;
                        }
                        return state;
                      },
                    },
                  ],
                },

              ],
            },
          ],
        },
        {
          type: "其他",
          content: [
            {
              // 其他表头数据
              title: "水电房租",
              align: "center",
              children: [
                {
                  title: "费用",
                  key: "utilityRentExpense",
                  align: "center",
                  input: [
                    {
                      isEdit: () => {
                        let state = false;
                        if (this.addEdit.type == "detail") {
                          state = true;
                        }
                        return state;
                      },
                      method: (value, row, index) => {
                        this.changeAmount(value, row, index);
                      },
                      rules: (value) => {
                        let result = value.replace(/[^\d.]/g, '');
                        if (result.charAt(0) === '.') {
                          result = result.slice(1);
                        }
                        const parts = result.split('.');
                        if (parts.length > 2) {
                          result = parts.slice(0, 2).join('.');
                        }
                        if (parts.length > 1) {
                          result = `${parts[0]}.${parts[1].slice(0, 2)}`;
                        }
                        return result;
                      },
                    },
                  ],
                },
                {
                  title: "备注",
                  inputTooltip: true,
                  key: "utilityRentRemark",
                  align: "center",
                  input: [
                    {
                      isEdit: () => {
                        let state = false;
                        if (this.addEdit.type == "detail") {
                          state = true;
                        }
                        return state;
                      },
                    },
                  ],
                },

              ],
            },
            {
              title: "交通差旅费",
              align: "center",
              children: [
                {
                  title: "费用",
                  key: "travelExpensesExpense",
                  align: "center",
                  input: [
                    {
                      isEdit: () => {
                        let state = false;
                        if (this.addEdit.type == "detail") {
                          state = true;
                        }
                        return state;
                      },
                      method: (value, row, index) => {
                        this.changeAmount(value, row, index);
                      },
                      rules: (value) => {
                        let result = value.replace(/[^\d.]/g, '');
                        if (result.charAt(0) === '.') {
                          result = result.slice(1);
                        }
                        const parts = result.split('.');
                        if (parts.length > 2) {
                          result = parts.slice(0, 2).join('.');
                        }
                        if (parts.length > 1) {
                          result = `${parts[0]}.${parts[1].slice(0, 2)}`;
                        }
                        return result;
                      },
                    },
                  ],
                },
                {
                  title: "备注",
                  inputTooltip: true,
                  key: "travelExpensesRemark",
                  align: "center",
                  input: [
                    {
                      isEdit: () => {
                        let state = false;
                        if (this.addEdit.type == "detail") {
                          state = true;
                        }
                        return state;
                      },
                    },
                  ],
                },
              ],
            },
            {
              title: "验收费",
              align: "center",
              children: [
                {
                  title: "费用",
                  key: "inspectionExpense",
                  align: "center",
                  input: [
                    {
                      isEdit: () => {
                        let state = false;
                        if (this.addEdit.type == "detail") {
                          state = true;
                        }
                        return state;
                      },
                      method: (value, row, index) => {
                        this.changeAmount(value, row, index);
                      },
                      rules: (value) => {
                        let result = value.replace(/[^\d.]/g, '');
                        if (result.charAt(0) === '.') {
                          result = result.slice(1);
                        }
                        const parts = result.split('.');
                        if (parts.length > 2) {
                          result = parts.slice(0, 2).join('.');
                        }
                        if (parts.length > 1) {
                          result = `${parts[0]}.${parts[1].slice(0, 2)}`;
                        }
                        return result;
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: "人工费用",
          content: [
            // 人工费用表头数据
            {
              title: "外协劳务费",
              align: "center",
              children: [
                {
                  title: "单价",
                  key: "outsourcedLaborUnivalence",
                  align: "center",
                  input: [
                    {
                      isEdit: () => {
                        let state = false;
                        if (this.addEdit.type == "detail") {
                          state = true;
                        }
                        return state;
                      },
                      method: (value, row, index) => {
                        this.changeAmount(value, row, index);
                      },
                      rules: (value) => {
                        let result = value.replace(/[^\d.]/g, '');
                        if (result.charAt(0) === '.') {
                          result = result.slice(1);
                        }
                        const parts = result.split('.');
                        if (parts.length > 2) {
                          result = parts.slice(0, 2).join('.');
                        }
                        if (parts.length > 1) {
                          result = `${parts[0]}.${parts[1].slice(0, 2)}`;
                        }
                        return result;
                      },
                    },
                  ],
                },
                {
                  title: "费用",
                  key: "outsourcedLaborExpense",
                  align: "center",
                },

              ],
            },
            {
              title: "绩效工资",
              align: "center",
              children: [
                {
                  title: "单价",
                  key: "meritPayUnivalence",
                  align: "center",
                  input: [
                    {
                      isEdit: () => {
                        let state = false;
                        if (this.addEdit.type == "detail") {
                          state = true;
                        }
                        return state;
                      },
                      method: (value, row, index) => {
                        this.changeAmount(value, row, index);
                      },
                      rules: (value) => {
                        let result = value.replace(/[^\d.]/g, '');
                        if (result.charAt(0) === '.') {
                          result = result.slice(1);
                        }
                        const parts = result.split('.');
                        if (parts.length > 2) {
                          result = parts.slice(0, 2).join('.');
                        }
                        if (parts.length > 1) {
                          result = `${parts[0]}.${parts[1].slice(0, 2)}`;
                        }
                        return result;
                      },
                    },
                  ],
                },
                {
                  title: "费用",
                  key: "meritPayExpense",
                  align: "center",
                },

              ],
            },
            {
              title: "基本工资+社保",
              align: "center",
              children: [
                {
                  title: "单价",
                  key: "salaryInsuranceUnivalence",
                  align: "center",
                  input: [
                    {
                      isEdit: () => {
                        let state = false;
                        if (this.addEdit.type == "detail") {
                          state = true;
                        }
                        return state;
                      },
                      method: (value, row, index) => {
                        this.changeAmount(value, row, index);
                      },
                      rules: (value) => {
                        let result = value.replace(/[^\d.]/g, '');
                        if (result.charAt(0) === '.') {
                          result = result.slice(1);
                        }
                        const parts = result.split('.');
                        if (parts.length > 2) {
                          result = parts.slice(0, 2).join('.');
                        }
                        if (parts.length > 1) {
                          result = `${parts[0]}.${parts[1].slice(0, 2)}`;
                        }
                        return result;
                      },
                    },
                  ],
                },
                {
                  title: "费用",
                  key: "salaryInsuranceExpense",
                  align: "center",
                },

              ],
            },
          ],
        },
      ],
      tabArr: [
        // tabs 配置项 及 表格内容
        {
          name: "材料办公",
        },
        {
          name: "厨具伙食费用",
        },
        {
          name: "车辆租赁费用",
        },
        {
          name: "其他",
        },
        {
          name: "人工费用",
        },
        {
          name: "显示全部",
        },
      ],
    };
  },
  watch:{
    sumExpenseSubtotalZdy:{
      handler(val) {
        if(this.sumExpenseSubtotalZdy || this.sumExpenseSubtotalGd) {
          this.$set(this.$refs.assignForm.formData, 'budgetTotalAmount', (Number(this.sumExpenseSubtotalZdy || 0) + Number(this.sumExpenseSubtotalGd || 0)).toFixed(2))
        }
      },
    },
    sumExpenseSubtotalGd: {
      handler(val) {
        if(this.sumExpenseSubtotalZdy || this.sumExpenseSubtotalGd) {
          this.$set(this.$refs.assignForm.formData, 'budgetTotalAmount', (Number(this.sumExpenseSubtotalZdy || 0) + Number(this.sumExpenseSubtotalGd || 0)).toFixed(2))
        }
      }
    }
  },
  created() {
    this.getQuotaPercentageOptionGL();
    this.getQuotaPercentageOptionQC();
    if(this.addEdit.type != 'detail') {
      this.getAssignList();
    }
    this.getUnitList();
    this.getWorkTypePrice(this.tableDataFixed, 'fixed');
  },
  mounted() {
    if(this.addEdit.pageType = 'workout') {
      if (this.addEdit.type == 'add') {
        this.$nextTick(() => {
          this.personListDefalutData();
        })
      }

      if (this.addEdit.type == 'detail'  || this.addEdit.type == 'edit') {
        this.getDetailData(this.addEdit.id)
      }
    }
  },
  methods: {
    getQuotaPercentageOptionGL() {
      let params = {
        typeCode: ""
      }
        params.typeCode = 'CW-GLDEBFB';
        
        postApi(`/finance/dict/list/all`, params).then(res => {
          if(res.data.code == 0) {
            this.optionsGL = res.data.data.map(item => {
              return {
                label: item.name,
                value: Number(item.code),
              }
            })
          }
        })
    },
    getQuotaPercentageOptionQC() {
      let params = {
        typeCode: ""
      }
      params.typeCode = 'CW-DEBFB';
        postApi(`/finance/dict/list/all`, params).then(res => {
          if(res.data.code == 0) {
            this.optionsJC = res.data.data.map(item => {
              return {
                label: item.name,
                value: Number(item.code),
              }
            })
          }
        })
    },
    changeWorkType(val, row) {
      this.getWorkTypePrice(this.tableDataSource, '', row);
    },
    async getWorkTypePrice(tableData, str, row) {
    for(let item of tableData) {
      let res = await getApi(`/finance/finbudget/baselinesheet/query/univalence?type=1&code=${item.workType}`);
      if(res.data.code == 0) {
        
        if(!str) {
          this.tableDataSource.forEach((it, index) => {
            this.$set(item, 'outsourcedLaborUnivalence', res.data.data.outsourcedLaborUnivalence)
            this.$set(item, 'meritPayUnivalence', res.data.data.meritPayUnivalence)
            this.$set(item, 'salaryInsuranceUnivalence', res.data.data.salaryInsuranceUnivalence)
          })
        }
        else {
          this.$set(tableData[item.workType == 'BUDGET-WORK-TYPE-GL' ? 1 : 0], 'meritPayUnivalence', res.data.data.meritPayUnivalence)
          this.$set(tableData[item.workType == 'BUDGET-WORK-TYPE-GL' ? 1 : 0], 'salaryInsuranceUnivalence', res.data.data.salaryInsuranceUnivalence)
        }
      }
      if(row) {
        this.changeAmount('', row, '')
      }
    }
    },
    getStepList(jbxxData) {
      getApi(`/activiti/process/viewPage/${jbxxData.processInstanceId}`).then(res => {
        let { data } = res;
        if(res.data.code == 0) {
          this.activityList = data.data;
          this.activityList.forEach((item,index) => {
            if(item.isCurrentActiviti == 1 || item.isHistoricActiviti == 1) {
              this.activeIndex = index + 1
            }
          })
            if (jbxxData.taskNodes) {
                  this.activityList.forEach((item) => {
                    item.description = [];
                    jbxxData.taskNodes.forEach((val) => {
                      if (item.activityName == val.taskName) {
                            item.description.push(val)
                      }
                    })
                  })
                }
        }
      })
    },
    getDetailData(id) { // 获取详情数据
      getApi(`/finance/budget/establishment/${id}`).then(res => {
          if(res.data.code == 0) {
            let {data} = res;
            this.$refs.PersonForm.formData = {
              applierName: data.data.applierName,
              applyDate: data.data.applyDate,
              applierId: data.data.applierId,
            }
            let { finBudgetEstablishmentScGds, finBudgetEstablishmentScZdys, ...jbxxData} = data.data
            this.$refs.assignForm.formData =  jbxxData
            this.tableDataSource = finBudgetEstablishmentScZdys
            this.tableDataSource.forEach((item, i) => {
              item.index = i + 1
            })
            if(jbxxData.status == 'CW-YSBZ-ZT-QY') {
            }
            this.tableDataFixed = finBudgetEstablishmentScGds
            if(this.addEdit.showStep && jbxxData.processInstanceId) {
              // this.getStepList(jbxxData);
              this.tableDataSources = data.data.taskNodes
            }
          }
        })
    },
    personListDefalutData() {  // 编制人add状态 默认值
      this.$refs.PersonForm.formData = {
            applierName: sessionStorage.getItem("fullName"),
            applyDate: this.$moment().format('YYYY-MM-DD'),
            applierId: sessionStorage.getItem("userId"),
          }
    },
    // tabs 切换 表头
    filterColums() {
      let arr = [];
      this.ColumnsAllocation.forEach((item) => {
        if (this.activeName == "显示全部") {
          item.content.map((it) => {
            arr.push(it);
          });
        } else if (item.type == this.activeName) {
          item.content.map((it) => {
            arr.push(it);
          });
        } else {
        }
      });
      this.tableColumns = this.ColumnsConfig.concat(arr);
    },
    addWork() {
      if(this.addEdit.type == 'add' || this.addEdit.type == 'edit') {
        this.tableDataSource.push({
          index: this.tableDataSource[this.tableDataSource.length - 1].index + 1
        });
      }
    },
    delWork() {

      if(this.addEdit.type == 'detail') {
        return false;
      }
      if(this.selectTableSource.length == 0) {
        this.$message.warning('请先至少勾选一条数据')
        return false
      }


      if(this.tableDataSource.length == 1 || this.selectTableSource.length == this.tableDataSource.length) {
        this.$message.warning('最少保留一条数据')
        return false
      }
      let arr = this.tableDataSource.filter(item => !this.selectTableSource.some(v => v.index  === item.index ))
      this.selectTableSource = [];
      this.$refs.multipleTable.clearSelect();
      arr.forEach((item, index) => {
        item.index = index + 1
      })
      this.tableDataSource = arr;

    },
    handleClick(tab, event) { // 切换tabs 更改表头
      this.filterColums();
    },
    numMulti(num1, num2) { 
      var baseNum = 0; 
      try { 
      baseNum += num1.toString().split(".")[1].length; 
      } catch (e) { 
      } 
      try { 
      baseNum += num2.toString().split(".")[1].length; 
      } catch (e) { 
      } 
      return Number(num1.toString().replace(".", "")) * Number(num2.toString().replace(".", "")) / Math.pow(10, baseNum); 
    },
    changeQuotaPercentage(value, row) {
      if(row.standardWorkday && row.quotaPercentage) {
        const workday = Math.ceil(this.numMulti(row.quotaPercentage, row.standardWorkday));
        this.$set(row, 'workday', workday)
      }
      this.changeAmountFixed('', row, '')
    },
    changeAmountFixed(value, row, index) {
      if(row.workday) {
        if(row.salaryInsuranceUnivalence) { // 基本工资+ 社保
          const salaryInsuranceExpense = parseFloat(row.salaryInsuranceUnivalence * row.workday).toFixed(2);
          this.$set(row, 'salaryInsuranceExpense', salaryInsuranceExpense)
        }

        if(row.meritPayUnivalence) { // 绩效工资
          const meritPayExpense = parseFloat(row.meritPayUnivalence * row.workday).toFixed(2);
          this.$set(row, 'meritPayExpense', meritPayExpense)
        }
      }
      if(row.workday && (row.salaryInsuranceExpense || row.meritPayExpense)) {
        const expenseSubtotal = parseFloat(((row.salaryInsuranceExpense || 0) * 1) +  ((row.meritPayExpense || 0) * 1)).toFixed(2);
        this.$set(row, 'expenseSubtotal', expenseSubtotal)
      }

    },
    changeAmount(value, row, index) { // 计算示例
      if(row.totalEffort && row.efficiency) { 
        const workday = Math.ceil(row.totalEffort / row.efficiency);
        this.$set(row, 'workday', workday)
        let tableFixedWorkDay = 0;
        this.tableDataSource.forEach(item => {
          if(item.workType != 'BUDGET-WORK-TYPE-WYWX' && item.workType != 'BUDGET-WORK-TYPE-NYWX') {
              tableFixedWorkDay += Number(item.workday)
            }
        })
        this.tableDataFixed.forEach(item => {
          this.$set(item, 'standardWorkday', tableFixedWorkDay);
          this.changeQuotaPercentage('', item)
        })
      }
      if(row.workday) {
        if(row.salaryInsuranceUnivalence || row.salaryInsuranceUnivalence === 0) { // 基本工资+ 社保
          const salaryInsuranceExpense = parseFloat(row.salaryInsuranceUnivalence * row.workday).toFixed(2);
          this.$set(row, 'salaryInsuranceExpense', salaryInsuranceExpense)
        }

        if(row.meritPayUnivalence || row.meritPayUnivalence === 0) { // 绩效工资
          const meritPayExpense = parseFloat(row.meritPayUnivalence * row.workday).toFixed(2);
          this.$set(row, 'meritPayExpense', meritPayExpense)
        }

        if(row.outsourcedLaborUnivalence || row.outsourcedLaborUnivalence === 0) { // 外协劳务费
          const outsourcedLaborExpense = parseFloat(row.outsourcedLaborUnivalence * row.workday).toFixed(2);
          this.$set(row, 'outsourcedLaborExpense', outsourcedLaborExpense)
        }

        if(row.vehicleRentalUnivalence) { // 车辆租赁费
          const vehicleRentalExpense = parseFloat(row.vehicleRentalUnivalence * row.workday).toFixed(2);
          this.$set(row, 'vehicleRentalExpense', vehicleRentalExpense)
        }

        if(row.cookingUtensilsUnivalence) { // 厨具伙食费用
          const cookingUtensilsExpense = parseFloat(row.cookingUtensilsUnivalence * row.workday).toFixed(2);
          this.$set(row, 'cookingUtensilsExpense', cookingUtensilsExpense)
        }
      }

      if (row.materialsOfficeExpense || row.cookingUtensilsExpense || row.vehicleRentalExpense || row.outsourcedLaborExpense
        || row.meritPayExpense || row.salaryInsuranceExpense || row.utilityRentExpense || row.inspectionExpense || row.travelExpensesExpense) {
        const expenseSubtotal = parseFloat(((row.materialsOfficeExpense || 0) * 1) + ((row.cookingUtensilsExpense || 0) * 1)
          + ((row.vehicleRentalExpense || 0) * 1) + ((row.outsourcedLaborExpense || 0) * 1)
          + ((row.meritPayExpense || 0) * 1) + ((row.salaryInsuranceExpense || 0) * 1) + ((row.utilityRentExpense || 0) * 1)
          + ((row.inspectionExpense || 0) * 1) + ((row.travelExpensesExpense || 0) * 1)).toFixed(2);
        this.$set(row, 'expenseSubtotal', expenseSubtotal)
      }
    },
    handleSelectionChange(val) {
      this.selectTableSource = val;
    },
    getAssignList() { // 获取任务书列表
      getApi(`/project/assign/budget/assign/list/sc/gr`).then(res => {
        if(res && res.data.code == 0) {
          let { data } =  res;
          let options = {
            value: "assignName",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, options)
        }
      })
    },
    changeAssignName(e) { // 任务书change事件 并回填表单内容
      if(e) {
        this.formList[0].optionsList.forEach(item => {
          if(item.assignId == e) {
            this.$refs.assignForm.formData = {...item}
          }
        })
      }
      else {
        this.$refs.assignForm.formData = {}
      }
      this.$set(this.$refs.assignForm.formData, 'budgetTotalAmount', ((Number(this.sumExpenseSubtotalZdy)) + (Number(this.sumExpenseSubtotalGd))).toFixed(2))
    },
    getSummaries(param) { // 自定义项表格合计方法
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (column.property == "expenseSubtotal" || column.property == "workday" || column.property == "materialsOfficeExpense"
        || column.property == "cookingUtensilsExpense"|| column.property == "vehicleRentalExpense"|| column.property == "outsourcedLaborExpense"
        || column.property == "meritPayExpense"|| column.property == "salaryInsuranceExpense"|| column.property == "utilityRentExpense"
        || column.property == "inspectionExpense"|| column.property == "travelExpensesExpense"
        ) {
          //如果是经费（正常的加减法）
          const values = data.map((item) => item[column.property] ? item[column.property] * 1 : 0);
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              var sum = 0;
              if (!isNaN(value)) {
                sum = Number(Number(prev) + Number(curr)).toFixed(2);
                return sum;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += " ";
          }
        }
      });
      this.sumExpenseSubtotalZdy = sums[8] || 0;
      return sums
    },
    getSummariesFixed(param) { // 固定项 表格合计方法
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (column.property == "expenseSubtotal" || column.property == "workday" || column.property == "meritPayExpense"
        || column.property == "salaryInsuranceExpense") {
          //如果是经费（正常的加减法）
          const values = data.map((item) => item[column.property] ? item[column.property] * 1 : 0);
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              var sum = 0;
              if (!isNaN(value)) {
                sum = Number(Number(prev) + Number(curr)).toFixed(2);
                return sum;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += " ";
          }
        }
      });
      this.sumExpenseSubtotalGd = sums[5] || 0;
      return sums
    },
    getUnitList() { // 获取计量单位下拉数据
      const params = {
        typeCode: "PRJ-GZBDW"
      }
      postApi('/finance/dict/list/all', params).then(res => {
        if(res.data.code == 0) {
          let { data } = res
          this.ColumnsConfig.forEach(item => {
            if(item.key == 'measureUnit') {
              item.selectInput.options = data.data.map((it) => {
                return {
                  label: it.name,
                  value: it.code
                }
              })
            }
          })
        }
      }).finally(() => {
        this.filterColums();
        this.getJobType();
      })
    },
    getJobType() { // 获取工种List
      const params = {
        typeCode: "BUDGET-WORK-TYPE"
      }
      postApi('/finance/dict/list/all', params).then(res => {
        if(res.data.code == 0) {
          let { data } = res
          this.ColumnsConfig.forEach(item => {
            if(item.key == 'workType') {
              item.selectInput.options = data.data.map((it) => {
                return {
                  label: it.name,
                  value: it.code
                }
              })
            }
          })
          this.tableColumnsFixed.forEach(item => {
            if(item.key == 'workType') {
              item.selectInput.options = data.data.map((it) => {
                return {
                  label: it.name,
                  value: it.code
                }
              })
            }
          })
          
        }
      })
    },
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    SetCellClassName({row, column, rowIndex, columnIndex}) {
      if(column.property == 'jobContent' || column.property == 'measureUnit' || column.property == 'totalEffort' || column.property == 'workType'
       || column.property == 'efficiency'|| column.property == 'workday'|| column.property == 'expenseSubtotal' || column.property == 'index') {
        return 'backgroundColor'
       }
    },
  },
};
</script>

<style lang="less" scoped>
.buttonBox {
  display: flex;
  align-items: center;
  margin: 4px 0;
}
.explain{
  margin-left: 30px;
  color: #00000075;
}
/deep/.el-tabs__header {
  margin: 0;
}
/deep/ .el-tabs__item {
  font-weight: 600;
  border-bottom: none;
  width: 16.67% !important;
}
/deep/.el-tabs--card > .el-tabs__header {
  border-bottom: none;
}
/deep/.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  background-color: #576289;
  border-bottom: none;
}
/deep/.el-tabs__item.is-active {
  color: @systemColor !important;
}
/deep/.el-tabs__item:hover {
  color: #000 !important;
}
/deep/.el-tabs__item.is-active:focus {
  color: @systemColor !important;
}

/deep/ .el-input__icon {
  line-height: 30px;
}
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
  resize: none;
}
// /deep/ .el-input__suffix {
//   display: none;
// }
/deep/ .el-range-editor.is-disabled {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/ .el-range-editor.is-disabled input {
  color: @defaultFontColor;
}
/deep/ .el-descriptions-item__label {
  color: #606266;
  font-weight: bold;
}
/deep/ .el-descriptions-item__content {
  color: @defaultFontColor;
}
/deep/ .table_cell {
  .backgroundColor {
    background-color: #d2e5fa !important;
  }
}
</style>
