<template>
  <div class="container">
    <dd-card title="">
      <dd-form
        :form-config="formList"
        ref="addPopForm"
        formRef="addPopForm"
        :flex="2"
        labelWidth="180px"
      ></dd-form>
    </dd-card>
  </div>
</template>

<script>
export default {
  name: "addEditDetails",
  props: ["record"],
  data() {
    return {
      formList: [
        {
          label: "单据编号",
          value: "code",
          inputType: "text",
          placeholderText: "提交后生成",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "记录日期",
          value: "recordDate",
          placeholderText: "记录日期",
          inputType: "date",
          dateType: "date",
          defaultValue: "",
          disabled: true,
        },
        {
          label: "自建采购合同名称",
          value: "selfContractName",
          inputType: "text",
          placeholderText: "提交后生成",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "自建采购合同编号",
          value: "selfContractCode",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "金蝶合同名称",
          value: "kingContractName",
          inputType: "text",
          placeholderText: "提交后生成",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "金蝶合同编号",
          value: "kingContractCode",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "销售合同名称",
          value: "saleContractName",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "销售合同编号",
          value: "saleContractCode",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "销售合同甲方",
          value: "partAId",
          disabled: true,
          inputType: "text",
          placeholderText: "关联销售合同",
        },
        {
          label: "已用金额",
          value: "amount",
          inputType: "text",
          placeholderText: "请输已用金额",
          disabled: this.record.pageType == "detail",
          rules: [
            {
              required: true,
              message: "请输入已用金额",
              trigger: "blur",
            },
            {
              pattern: /^(-?\d*\.\d{1,2}|-?\d{1,9})$/,
              required: true,
              message: "请输入正确的格式",
              trigger: "blur",
            },
          ],
        },
        {
          label: "备注",
          placeholderText: "请输入备注",
          disabled: true,
          value: "remark",
          inputType: "text",
          type: "textarea",
          defaultValue: "",
          rows: "2",
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.formList.forEach((item) => {
      item.defaultValue = this.record[item.value];
    });
  },
  methods: {
    // getPageData() {
    //   getApi(`/contract/specialrecord?id=${this.pageProp.id}`).then((res) => {
    //     if (res.data.code == 0) {
    //       if (res.data.data) {
    //         this.$refs.addPopForm.formData = { ...res.data.data };
    //         this.$refs.addPopForm.formData["recordDate"] =
    //           res.data.data.recordDate;
    //       }
    //     }
    //   });
    // },
  },
};
</script>

<style lang='less' scoped>
/deep/ .el-range-editor.is-disabled {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}

/deep/ .el-range-editor.is-disabled input {
  color: @defaultFontColor;
}

/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}

/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
  resize: none;
}
</style>