<template>
  <div class="main-container">
    <dd-card title="条件搜索" spacer>
      <dd-search-form :form-config="formList" @search-click="handleSearchEvent" ref="ddSearchForm"
        labelWidth="180px"></dd-search-form>
    </dd-card>
    <dd-card title="绩效核算列表" :height="tableHeight">
      <dd-table :columns="tableColumns" :data="tableData" border ref="TableRef" show-page :loading="loading"
        @select-change="handleSelectionChange">
        <template #table-btn>
          <dd-button type="primary" size="medium" icon="add" @click="handleAdd(0)">新建绩效核算单</dd-button>
          <dd-button type="warning" size="medium" autoConfirm @click="handleAdd(1)">工作量绩效公示确认</dd-button>
          <dd-button type="primary" size="medium" @click="backPerformanceAccounting()"
            v-if="$store.state.btnAuthorityList.includes('bt:return:performance')">退回绩效核算</dd-button>
          <dd-button icon="export" type="success" size="medium" autoConfirm @click="handleFileExport">导出</dd-button>
        </template>
        <template #table-btn-right>
          <el-button icon="el-icon-s-operation" circle type="info" @click="drawerVisible = true" size="small"
            title="列筛选"></el-button>
        </template>
        <template #table-pagination>
          <dd-pagination v-show="total > 0" :total="total" :page-size="size" :page-number="current"
            @page-change="handleCurrentChange" align="right">
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <!-- 添加 -->
    <dd-popup width="40%" height="30%" :showBtn="showBtn" :saveBtn="false" :cancelBtn="false" :show="visiblePopAdd"
      :title-name="poptitleName" @popup-close="handleClose">
      <add-pop v-if="visiblePopAdd" slot="main" ref="addPop" :titleName="poptitleName" :rowData="rowData"></add-pop>
      <template slot="button">
        <dd-button size="medium"  @click="handleClose" >取消</dd-button>
        <dd-button type="primary" size="medium" :loading="handledisable" @click="handleSave('addPopForm', 'baseForm', 0)">保存</dd-button>
      </template>
    </dd-popup>
    <!-- 公示确认-->
    <dd-popup v-if="publicityPopDialogVisible" width="30%" height="50%" showBtn :saveBtn="false" submitBtn
      :title-name="popTitle" :show="publicityPopDialogVisible" @popup-close="handleClose" @popup-submit="handleSubmit">
      <publicityPop slot="main" ref="publicityPop" :id="id"></publicityPop>
    </dd-popup>
    <!-- 查看详情 -->
    <dd-popup width="70%" height="80%" :showBtn="showBtn" :show="visiblePop" :title-name="poptitleName"
      @popup-close="handleClose">
      <add-pop-detail v-if="addPopupVisible" slot="main" ref="addPop" :titleName="poptitleName"
        :rowData="rowData"></add-pop-detail>
    </dd-popup>
    <!-- 公示确认详情-->
    <dd-popup v-if="publicityDetailPopDialogVisible" width="70%" height="80%" :showBtn="false" :title-name="popTitle"
      :show="publicityDetailPopDialogVisible" :inPopup="true" @popup-close="handleClose">
      <publicityDetailPop slot="main" :id="id"></publicityDetailPop>
    </dd-popup>
    <dd-filter-drawer v-if="drawerVisible" width="30%" title-name="列筛选" :show="drawerVisible"
      :tableColumns="tableColumns" :columns="columnsList" @popup-close="drawerVisible = false"
      @handleColumnsChange="handleColumnsChange">
    </dd-filter-drawer>
  </div>
</template>

<script>
import { postApi, putApi, loadApi, delApi, getApi } from "@/api/request";
// 新增页面弹窗
import addPop from "./components/addPop.vue";
import addPopDetail from "./components/AddPopDetail.vue";
import publicityPop from "./components/publicityPop.vue";
import publicityDetailPop from "./components/publicityPopDetail.vue";
import { downloadBinaryFile } from "@/utils/index.js";
import { formatDateTime } from "@/utils/date";
import { disable } from "ol/rotationconstraint";
export default {
  name: "accountingManage",
  $dynamicRoute: {
    name: "绩效核算管理",
    path: "/performanceAccounting/accountingManage",
  },
  components: {
    addPop, addPopDetail, publicityPop, publicityDetailPop
  },
  computed: {},
  data() {
    return {
      handledisable:false,
      drawerVisible: false,
      showBtn: false,
      visiblePop: false,
      addPopupVisible: false,
      addVisible: false,
      visiblePopAdd: false,
      publicityPopDialogVisible: false,
      publicityDetailPopDialogVisible: false,
      multipleSelection: [],  // 表格选择数据集合
      tableHeight: null,
      loading: true,
      formData: {},
      formList: [
        {
          label: "单据名称",
          value: "name",
          inputType: "text",
          placeholderText: "请输入单据名称",
        },
        {
          label: "部门",
          value: "deptId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
          defaultValue: '',
          placeholderText: "请选择部门",
          rules: [
            {
              required: true,
              message: "请选择部门",
              trigger: "blur"
            }
          ],
        },
        {
          label: "状态",
          value: "status",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择或搜索状态",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
        },
        {
          label: "所属年月",
          value: "applyDate",
          inputType: "date",
          dateType: "monthrange",
          format: "yyyy-MM",
        },
        {}, {}, {}
      ],
      tableColumns: [
        {
          title: "单据编码",
          key: "code",
          align: "center",
        },
        {
          title: "单据名称",
          key: "name",
          align: "center",
          width: "340",
        },
        {
          title: "部门",
          key: "deptName",
          align: "center",
        },
        // {
        //   title: "绩效核算组",
        //   key: "accountingGroupName",
        //   align: "center",
        // },
        {
          title: "所属年月",
          key: "reportMonth",
          align: "center",
        },
        {
          title: "开始时间点",
          key: "startTime",
          align: "center",
        },
        {
          title: "截止时间点",
          key: "deadLine",
          align: "center",
        },
        {
          title: "统计工作量类型",
          key: "standardWorkpackageType",
          align: "center",
          width: '150',
        },
        {
          title: "状态",
          key: "statusName",
          align: "center",
        },
        {
          title: "操作",
          key: "handle",
          align: "center",
          fixed: "right",
          width: '300',
          event: [
            {
              label: "编辑完成",
              icon: "el-icon-document",
              type:"success",
              method: (row) => {
                this.handleCalculate(row);
              },
              rules: (row) => {
                let show = false;
                if (row.status == "PRJ-ASSIGN-PERFORMANCE-ACCOUNTING-HSZ" || row.status == "PRJ-ASSIGN-PERFORMANCE-ACCOUNTING-YTH") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              label: "编辑",
              icon: "el-icon-edit",
              type:"primary",
              method: (row) => {
                this.handleAdd(3, row);
              },
              rules: (row) => {
                let show = false;
                if (row.status == "PRJ-ASSIGN-PERFORMANCE-ACCOUNTING-HSZ" || row.status == "PRJ-ASSIGN-PERFORMANCE-ACCOUNTING-YTH") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              label: "查看详情",
              icon: "el-icon-view",
              type: "info",
              method: (row) => {
                this.handleAdd(4, row);
              },
              rules: (row) => {
                let show = false;
                if (row.status != "PRJ-ASSIGN-PERFORMANCE-ACCOUNTING-HSZ") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              label: "查看公示情况",
              icon: "el-icon-view",
              state: "default",
              type: "info",
              method: (row) => {
                this.handleAdd(2, row);
              },
              rules: (row) => {
                let show = false;
                if (row.status == "PRJ-ASSIGN-PERFORMANCE-ACCOUNTING-YQR"
                  || row.status == "PRJ-ASSIGN-PERFORMANCE-ACCOUNTING-QRZ"
                  || row.status == "PRJ-ASSIGN-PERFORMANCE-ACCOUNTING-YHSJX"
                ) {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              label: "删除",
              icon: "el-icon-delete",
              method: (row) => {
                this.handleRowDel(row);
              },
              type: "danger",
              rules: (row) => {
                let show = false;
                if (row.status == "PRJ-ASSIGN-PERFORMANCE-ACCOUNTING-HSZ") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
          ],
        },
      ],
      tableData: [],
      current: 1,
      size: 10,
      total: null,
      rowData: null,
      poptitleName: "新增",
      id: '',
    };
  },
  created() { },
  mounted() {
    this.getBaseInfo();
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.getTableData();
  },
  methods: {
    backPerformanceAccounting() {
      let { multipleSelection } = this;
      if (multipleSelection.length == 0 || multipleSelection.length > 1) {
        this.$message.warning("请选择一条数据");
        return false;
      }
      if (multipleSelection[0].status == 'PRJ-ASSIGN-PERFORMANCE-ACCOUNTING-YQR' || multipleSelection[0].status == 'PRJ-ASSIGN-PERFORMANCE-ACCOUNTING-QRZ' || multipleSelection[0].status == 'PRJ-ASSIGN-PERFORMANCE-ACCOUNTING-DQR') {
        getApi(`/project/performanceaccounting/return?id=${multipleSelection[0].id}`).then(res => {
          if (res.data.code == 0) {
            this.$message.success('操作成功');
          }
          else {
            this.$message.warning('操作失败，请稍后重试');
          }
          this.$refs.TableRef.$refs.baseTable.clearSelection();
          this.multipleSelection = [];
          this.getTableData();
        })
      }
      else {
        this.$message.warning("只能退回状态为已确认，确认中，待确认的绩效核算");
      }

    },
    getBaseInfo() {
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "deptId") {
              item.optionsList = data.data;
            }
          });
        }
      });
      // 单据状态下拉数据请求
      postApi(`/project/prj/dict/list/all`, {
        typeCode: "PRJ_ASSIGN_PERFORMANCE_ACCOUNTING_STATE",
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "status") {
              item.optionsList = data.data;
            }
          });
        }
      });
    },
    // 获取表格列表数据
    getTableData() {
      let { formData, current, size } = this;
      let params = {
        ...formData,
      };
      this.loading = true;
      postApi(
        `/project/performanceaccounting/page/${current}/${size}`,
        params
      ).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.loading = false;
          this.tableData = data.data.records;
          this.total = data.data.total;
        }
      });
    },
    // 核算
    handleCalculate(row) {
      this.$confirm("是否确定核算？", "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        let params = {
          id: row.id
        };
        putApi(`/project/performanceaccounting`, params).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: "核算成功!",
            });
            setTimeout(() => {
              this.getTableData();
            }, 500)
          }
        })
      }).catch((err) => {
        console.log(err);
      });
    },
    // 新增按钮事件
    handleAdd(type, row) {
      // type 0: 新建；1：公示确认；2：查看公示情况；3：编辑 4：查看详情
      if (type == 0) {
        this.showBtn = true;
        this.visiblePopAdd = true;
        this.poptitleName = "新增";
      } else if (type == 1) {
        let { multipleSelection } = this;
        if (multipleSelection.length == 0 || multipleSelection.length > 1) {
          this.$message.warning("请选择一条数据进行公示确认!");
          return;
        }
        else if (multipleSelection[0].status == 'PRJ-ASSIGN-PERFORMANCE-ACCOUNTING-HSZ') {  // 核算中
          this.$message.warning("请先核算数据!");
          return;
        }
        else if (multipleSelection[0].status == 'PRJ-ASSIGN-PERFORMANCE-ACCOUNTING-DQR') {// 待确认
          this.id = multipleSelection[0].id;
          this.popTitle = "工作量绩效公示确认";
          this.publicityPopDialogVisible = true;
        }
        else if (multipleSelection[0].status == "PRJ-ASSIGN-PERFORMANCE-ACCOUNTING-QRZ") {  // 确认中
          this.$message.warning("该条数据在确认中!");
          return;
        }
        else if (multipleSelection[0].status == "PRJ-ASSIGN-PERFORMANCE-ACCOUNTING-YQR") { // 已确认
          this.$message.warning("该条数据已确认!");
          return;
        }
      } else if (type == 2) {
        this.id = row.id;
        this.popTitle = "公示情况";
        this.publicityDetailPopDialogVisible = true;
        this.showBtn = false;
      } else if (type == 3) {
        this.showBtn = false;
        this.visiblePop = true;
        this.addPopupVisible = true;
        this.rowData = row;
        this.poptitleName = "编辑";
      } else {
        this.showBtn = false;
        this.visiblePop = true;
        this.addPopupVisible = true;
        this.rowData = row;
        this.poptitleName = "查看详情";
      }
    },
    // 表格多选事件
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 导出
    handleFileExport() {
      let { multipleSelection } = this;
      if (multipleSelection.length == 0 || multipleSelection.length > 1) {
        this.$message.warning("请选择一条数据进行导出");
        return;
      }
      let params = {
        id: multipleSelection[0].id,
      };
      loadApi(`/project/performanceaccounting/export`, params).then((res) => {
        let { data } = res;
        this.$refs.TableRef.$refs.baseTable.clearSelection();
        this.multipleSelection = [];
        downloadBinaryFile(
          data,
          decodeURIComponent(
            res.headers["content-disposition"].split(";")[1].split("=")[1]
          )
        );
      });
    },
    // 新增弹框关闭事件
    handleClose() {
      this.rowData = null;
      this.visiblePop = false;
      this.visiblePopAdd = false;
      this.addPopupVisible = false;
      this.publicityPopDialogVisible = false;
      this.publicityDetailPopDialogVisible = false;
    },
    // 表格行内编辑事件
    handleRowEditor(row) {
      this.addPopupVisible = true;
      this.rowData = row;
      this.poptitleName = "编辑";
    },
    //列表单行删除
    handleRowDel(row) {
      this.$confirm(`确认删除该数据吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        this.handleConfirmDelete(row.id);
      });
    },
    // 确认删除请求
    handleConfirmDelete(delId) {
      delApi(`/project/performanceaccounting/${delId}`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.$message.success("删除成功");
        }
        this.getTableData();
      });
    },
    /* 搜索栏 */
    handleSearchEvent(obj) {
      this.formData = obj;
      this.current = 1;
      this.getTableData();
    },
    /* 分页页码回调 */
    handleCurrentChange(size, number) {
      this.current = number;
      this.size = size;
      this.getTableData();
    },
    // 新建绩效核算单 确认事件
    handleSave(formName, formNameBase, type) {
      let flag = false;
      this.$refs.addPop.$refs[formName].$refs[formNameBase].validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      let params = this.$refs.addPop.$refs[formName].formData;
      if (flag) {
        this.handledisable = true;
        if (this.rowData) {
          params.id = this.rowData.id;
        }
        postApi(`/project/performanceaccounting`, params).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.$message.success("保存成功");
            this.handledisable = false;
            this.handleClose();
            this.getTableData();
          }
        });
      }
    },
    // 工作量公示 确认事件
    handleSubmit() {
      let { radio, checkList, deadLine } = this.$refs.publicityPop;
      // if (!radio) {
      //   this.$message.warning("请选择公示类型");
      //   return;
      // } else 
      if (checkList.length == 0 && checkList[0] == undefined) {
        this.$message.warning("请选择公示类型");
        return;
      } else if (!deadLine) {
        this.$message.warning("请选择公示与确认截止时间");
        return;
      }
      let list = [...checkList];
      let url = `/project/performanceaccounting/confirm?id=${this.id}&type=${list.toString()}&deadLine=${formatDateTime(deadLine)}`;
      postApi(url, {}).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.$message.success("提交成功");
          this.$refs.TableRef.$refs.baseTable.clearSelection();
          this.multipleSelection = [];
          this.handleClose();
          this.getTableData();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
