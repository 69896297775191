<template>
  <div class="main-container">
    <div class="head_wrap">
      <dd-card title="条件搜索" spacer>
        <dd-search-form
          :form-config="formList"
          @search-click="handleSearchEvent"
          ref="ddSearchForm"
        ></dd-search-form>
      </dd-card>
    </div>
    <dd-card title="采购合同汇总列表" :height="tableHeight">
      <dd-table
        :columns="columnsList"
        :data="tableData"
        ref="selfBuiltContract"
        @select-change="handleSelect"
        border
        :isSelection="true"
        show-page
        :loading="loading"
      >
        <template #table-btn>
          <dd-button
            type="info"
            size="medium"
            @click="handleExport"
            icon="export"
            >导出
          </dd-button>
        </template>
        <template #table-btn-right>
          <el-button
            icon="el-icon-s-operation"
            circle
            type="info"
            size="small"
            @click="drawerVisible = true"
            title="列筛选"
          ></el-button>
        </template>
        <template #table-pagination>
          <dd-pagination
            v-show="total > 0"
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handlePageChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="drawerVisible = false"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>

    <dd-popup
      v-if="detailVisible"
      width="100%"
      height="100%"
      showBtn
      :saveBtn="false"
      :submitBtn="false"
      :show="detailVisible"
      title-name="查看详情"
      @popup-close="handleClose"
    >
      <contractDetail
        ref="detailVisible"
        :pageProp="pageProp"
        slot="main"
      ></contractDetail>
    </dd-popup>
  </div>
</template>

<script>
import contractDetail from "./components/contractDetail.vue";
import { delApi, postApi, putApi, loadApi } from "@/api/request";
import { downloadBinaryFile } from "@/utils/index";
export default {
  name: "recordPurchase",
  $dynamicRoute: {
    name: "按采购合同汇总",
    path: "/contact/recordPurchase/index",
  },
  components: { contractDetail },
  data() {
    return {
      detailVisible: false,
      loading: false,
      drawerVisible: false,
      componentKey: 0,
      pageProp: {},
      columnsList: [],
      tableData: [],
      tableColumns: [
        {
          title: "采购年度",
          key: "procurementYear",
          align: "center",
          overflow: true,
        },
        {
          title: "自建采购合同编号",
          key: "code",
          align: "center",
          overflow: true,
        },
        {
          title: "自建采购合同名称",
          key: "name",
          align: "center",
          overflow: true,
        },
        {
          title: "本合同变更后总金额",
          key: "procurementFinallyAmount",
          align: "center",
          overflow: true,
        },
        {
          title: "关联金蝶采购金额",
          key: "purchaseAmount",
          align: "center",
          overflow: true,
        },
        {
          title: "已结算金额",
          key: "procurementSettleAmount",
          align: "center",
          overflow: true,
        },
        {
          title: "实付金额",
          key: "procurementPaymentAmount",
          align: "center",
          overflow: true,
        },
        {
          title: "已用金额合计",
          key: "recordUsedAmount",
          align: "center",
          overflow: true,
        },
        {
          title: "操作",
          align: "center",
          fixed: "right",
          overflow: true,
          width: 200,
          event: [
            {
              state: "default",
              label: "查看详情",
              width: 100,
              method: (row) => {
                this.handleRowDetail(row);
              },
            },
          ],
        },
      ],
      formList: [
        {
          label: "自建合同编号",
          value: "code",
          inputType: "text",
          placeholderText: "请输入自建合同编号进行搜索",
        },
        {
          label: "自建合同名称",
          value: "name",
          inputType: "text",
          placeholderText: "请输入自建合同名称进行搜索",
        },
        {
          label: "已用金额合计(开始)",
          value: "amountBegin",
          inputType: "number",
          placeholderText: "请输入已用金额合计",
        },
        {
          label: "已用金额合计(结束)",
          value: "amountEnd",
          inputType: "number",
          placeholderText: "请输入已用金额合计",
        },
        {},
        {},
        {},
      ],
      selectData: [],
      seachData: {},
      tableHeight: null,
      saveBtn: false,
      // 当前页码
      current: 1,
      // 总条数
      total: 0,
      // 每页的数据条数
      size: 10,
    };
  },
  created() {
    this.getTableData();
  },
  mounted() {
    this.columnsList = [...this.tableColumns];
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
  },
  methods: {
    getTableData() {
      let param = {
        ...this.seachData,
        recordPurchase: "recordPurchase",
      };
      postApi(
        `/contract/specialrecord/self/page/${this.current}/${this.size}`,
        param
      ).then((res) => {
        if (res.data.code == 0) {
          this.total = res.data.data.total;
          this.tableData = res.data.data.records;
        }
        this.clearTableSelection();
      });
    },
    handleSearchEvent(e) {
      this.current = 1;
      this.seachData = e;
      this.getTableData();
    },
    handleSelect(e) {
      this.selectData = e;
    },
    handleExport() {
      let params = {
        recordPurchase: "recordPurchase",
        ...this.seachData,
        ids: this.selectData.map((item) => {
          return item.id;
        }),
      };

      loadApi(`/contract/specialrecord/record/purchase/export`, params).then(
        (res) => {
          let { data } = res;
          this.$refs.selfBuiltContract.$refs.baseTable.clearSelection();
          downloadBinaryFile(
            data,
            decodeURIComponent(
              res.headers["content-disposition"].split(";")[1].split("=")[1]
            )
          );
          this.$message.success("导出成功");
        }
      );
    },
    handleRowDetail(row) {
      this.pageProp.pageType = "detail";
      this.pageProp.id = row.id;
      this.detailVisible = true;
    },
    handleClose() {
      this.detailVisible = false;
      this.getTableData();
      this.selectData = [];
      this.$refs.selfBuiltContract.$refs.baseTable.clearSelection();
    },
    handleColumnsChange(val) {
      let list = new Set();
      this.tableColumns.map((item) => {
        val.map((itm) => {
          if (item.event || item.key == itm.key) {
            list.add(item);
          }
        });
      });
      this.columnsList = [...list];
    },
    handlePageChange(pageSize, pageNumber) {
      this.size = pageSize;
      this.current = pageNumber;
      this.getTableData();
    },
    clearTableSelection() {
      this.$refs.selfBuiltContract.$refs.baseTable.clearSelection();
    },
  },
};
</script>

<style lang="less" scoped>
.search-form {
  /deep/ .el-form-item__label{
      width: 150px !important;
    }
}
</style>