<!--
 * @version: V1.0.0
 * @Author: hechen
 * @Date: 2023-03-22 10:39:43
 * @LastEditors: hechen
 * @LastEditTime: 2023-10-11 14:37:15
 * @company: 大地测绘
 * @Mailbox: 15291492336@163.com
 * @FilePath: \ssh-eom-web\src\components\BaseSearchForm\index.vue
 * @Descripttion: 系统条件查询组件
-->
<template>
  <div class="search-form">
    <el-form
      :model="formData"
      :label-width="labelWidth"
      ref="searchForm"
      @submit.native.prevent
    >
      <div class="search-form-group" v-if="isOpen">
        <el-form-item
          class="search-form-input"
          v-for="(item, index) in formConfig"
          :key="index"
          :label="item.label"
          :prop="item.value"
        >
          <el-input
            v-if="item.inputType == 'text'"
            size="small"
            :placeholder="item.placeholderText"
            v-model="formData[item.value]"
            class="input-value"
            clearable
          ></el-input>
          <!-- 下拉选择器 -->
          <el-select
          size="small"
            v-if="item.inputType == 'select'"
            v-model="formData[item.value]"
            :placeholder="item.placeholderText"
            :remote="item.remote ? item.remote : false"
            :remote-method="item.remoteMethod ? item.remoteMethod : occupyingFn"
            @change="
              item.change ? item.change($event, item.value) : occupyingFn
            "
            class="input-value"
            clearable
            :filterable="item.filterable"
            :multiple="item.multiple"
          >
            <el-option
              v-for="(val, idx) in item.optionsList"
              :key="idx"
              :label="
                val[
                  item.optionsListConfig ? item.optionsListConfig.name : 'name'
                ]
              "
              :value="
                val[
                  item.optionsListConfig ? item.optionsListConfig.code : 'code'
                ]
              "
            ></el-option>
          </el-select>
          <!-- 树形下拉选择器 -->
          <el-select
          size="small"
            v-if="item.inputType == 'selectTree'"
            ref="selectTree"
            v-model="formData[item.value]"
            :placeholder="item.placeholderText"
            class="input-value"
            clearable
            :filterable="item.filterable"
            :filter-method="filterNodes"
          >
            <el-option
              v-for="(item, idx) in optionData(item.optionsList)"
              :key="idx"
              :label="item.name"
              :value="item.id"
              style="display: none"
            />

            <el-tree
              ref="tree"
              :data="item.optionsList"
              :props="treeProps"
              highlight-current
              @node-click="handleNodeClick($event, item.value)"
              :expand-on-click-node="expandOnClickNode"
              :filter-node-method="filterNode"
              default-expand-all
            />
          </el-select>
          <el-date-picker
          size="small"
            v-if="item.inputType == 'date'"
            v-model="formData[item.value]"
            :type="item.dateType"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :value-format="item.format ? item.format : 'yyyy-MM-dd'"
            class="input-value"
            unlink-panels
            :placeholder="item.placeholderText"
            @change="
              item.change ? item.change($event, item.value) : occupyingFn
            "
            clearable
          ></el-date-picker>
          <el-cascader
          size="small"
            v-if="item.inputType == 'cascader'"
            v-model="formData[item.value]"
            :options="item.optionsList"
            :props="item.prop"
            @change="
              item.change ? item.change($event, item.value) : occupyingFn
            "
            :placeholder="item.placeholderText"
            :disabled="item.disabled"
            class="input-value"
            clearable
          ></el-cascader>
          <!-- 数字输入框inputType=number -->
          <el-input-number
            v-if="item.inputType == 'number'"
            size="medium"
            v-model="formData[item.value]"
            :placeholder="item.placeholderText"
            controls-position="right"
          >
          </el-input-number>
        </el-form-item>
        <el-form-item class="search-form-input">
          <template #label>
            <!-- 占位符 -->
            <div></div>
          </template>
          <div class="input-value">
            <dd-button
              type="primary"
              icon="search"
              size="small "
              @click="handleSubmit('searchForm')"
              >查询</dd-button
            >
            <dd-button
              type="warning"
              icon="refresh"
              size="small"
              @click="handleRefresh('searchForm')"
              >重置</dd-button
            >
          </div>
        </el-form-item>
      </div>
          <!-- <div class="input-value">
            <dd-button
              type="primary"
              icon="search"
              size="medium "
              @click="openAndClose"
              >{{isOpen ? '收起' : '展开'}}</dd-button
            >
          </div> -->
    </el-form>
  </div>
</template>

<script>
export default {
  name: "dd-search-form",
  props: {
    formConfig: {
      type: Array,
      default: [],
    },
    labelWidth: {
      type: String,
      default: "120px",
    },
  },
  data() {
    return {
      isOpen: true,
      formData: {},
      expandOnClickNode: false,
      treeProps: {
        children: "children",
        label: "name",
      },
      selectTree: false,
    };
  },
  watch: {
    formConfig: {
      handler() {
        this.getFormData();
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    openAndClose() {
      this.isOpen = !this.isOpen
      this.$forceUpdate()
    },
    //占位函数
    occupyingFn() {},
    getFormData() {
      let obj = {};
      this.formConfig &&
        this.formConfig.forEach((item) => {
          if (item.inputType == "date") {
            if (item.defaultValue) {
              obj[item.value] = item.defaultValue;
            } else {
              obj[item.value] = item.dateType == "daterange" ? [] : "";
            }
          } else {
            if (item.defaultValue) {
              obj[item.value] = item.defaultValue;
            } else {
              obj[item.value] = "";
            }
          }
        });
      this.$nextTick(() => {
        this.formData = obj;
      });
    },
    handleSubmit(formName) {
      if (this.selectTree) {
        this.$refs.tree[0].filter("");
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.formData = this.removeEmptyValues(this.formData);
          this.$emit("search-click", this.formData);
        } else {
          return false;
        }
      });
    },
    handleRefresh(formName) {
      if (this.selectTree) {
        this.$refs.tree[0].filter("");
      }
      this.$refs[formName].resetFields();
      this.formData = this.removeEmptyValues(this.formData);
      this.$emit("search-click", this.formData);
    },
    // 过滤空对象
    removeEmptyValues(obj) {
      return Object.keys(obj).reduce((acc, key) => {
        if (Array.isArray(obj[key])) {
          obj[key] = obj[key].filter((item) => item);
          obj[key] = obj[key].length == 0 ? null : obj[key];
        }

        if (obj[key] !== "" && obj[key] !== null && obj[key] !== undefined) {
          acc[key] = obj[key];
        }
        return acc;
      }, {});
    },

    optionData(array, result = []) {
      this.selectTree = true;
      array.forEach((item) => {
        result.push({ label: item.name, value: item.code });
        if (item.children && item.children.length !== 0) {
          this.optionData(item.children, result);
        }
      });
      return JSON.parse(JSON.stringify(result));
    },
    handleNodeClick(node, key) {
      this.formData[key] = node.name;
      this.$refs.selectTree[0].blur();
    },
    filterNodes(value) {
      this.$refs.tree[0].filter(value);
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
  },
};
</script>

<style lang="less" scoped>
.search-form {
  .search-form-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    
    .search-form-input {
      width: 25%;
      display: flex;
      align-items: center;
      margin: 0px 0;

      /deep/.el-form-item__label {
        display: flex;
        align-items: center;
        font-weight: bold;
        justify-content: flex-end;
        margin-right: 15px;
        padding: 0;
      }
      /deep/.el-form-item__content {
        width: 100%;
        margin-left: 0 !important;
      }
      .input-value {
        width: 100%;
      }
    }
  }
  /deep/ .el-form-item__label{
      font-size: 12px !important;
    }
}
</style>
