<template>
  <div class="tab-container">
    <div class="left-tree container-left-tree">
      <el-tree
        ref="tree"
        :data="TreeList"
        accordion
        :props="defaultProps"
        default-expand-all
        highlight-current
        :expand-on-click-node="false"
        node-key="id"
        :current-node-key="treeNodeId"
        @node-click="handleNodeClick"
      >
        <div class="custom-tree-node" slot-scope="{ node, data }">
          <div
            :class="
              data.nodeType == 'PRJ-RWJHJDLX-ZRW'
                ? 'zrw'
                : data.nodeType == 'PRJ-RWJHJDLX-GZB'
                ? 'gzb'
                : null
            "
          >
            {{ node.label }}
          </div>
        </div>
      </el-tree>
    </div>
    <div class="right-table">
      <dd-card class="table-tab">
        <el-tabs
          v-model="workActiveName"
          type="card"
          @tab-click="handleTabClick"
        >
          <el-tab-pane
            v-for="val in workTabList"
            :key="val.value"
            :label="val.label"
            :name="val.value"
          ></el-tab-pane>
        </el-tabs>
      </dd-card>
      <dd-card v-if="workActiveName == 'ztjhjd'" :height="tableHeight">
        <dd-table
          :columns="nodeTableColumns"
          :data="nodeTableData"
          border
          :isSelection="false"
          :loading="loading"
          :tree-props="{ children: 'children' }"
          default-expand-all
          :row-class-name="tableRowClassName"
          :cell-class-name="tableCellClassName"
        >
          <template #table-btn>
            <dd-button
              type="primary"
              size="medium"
              icon="export"
              @click="handleAllExport"
              >导出</dd-button
            >
          </template>
        </dd-table>
      </dd-card>
      <!-- 编辑实际工作量弹窗 -->
      <dd-popup
        width="30%"
        height="50%"
        showBtn
        :saveBtn="true"
        :cancelBtn="true"
        :show="addPopupVisible"
        title-name="编辑实际量"
        @popup-close="handleClose"
        @popup-save="handleRealWorkSave('addPopForm', 'baseForm')"
      >
        <editRealWorkLoad
          v-if="addPopupVisible"
          :rowData="rowData"
          slot="main"
          ref="editPop"
        >
        </editRealWorkLoad>
      </dd-popup>
      <dd-card v-if="workActiveName == 'qjjhyhb'" :height="tableHeight">
        <dd-table
          ref="qjjhyhbTable"
          :columns="docTableColumns"
          :data="docTableData"
          border
          show-page
          :loading="loading"
          @select-change="handleSelectionChange"
        >
          <template #table-btn>
            <dd-button
              type="primary"
              size="medium"
              icon="add"
              @click="handleAdd"
              >新增计划</dd-button
            >
            <dd-button
              type="success"
              size="medium"
              icon="import"
              @click="handleSubmit"
              >计划发布</dd-button
            >
            <!-- <dd-button
              type="warning"
              size="medium"
              icon="export"
              @click="handleImport"
              >导入期间计划</dd-button
            >
            <dd-button
              type="info"
              size="medium"
              icon="import"
              @click="handlePlanExport"
              >导出期间计划</dd-button
            > -->
          </template>
          <template #table-pagination>
            <dd-pagination
              v-show="total > 0"
              :total="total"
              :page-size="size"
              :page-number="current"
              @page-change="handleCurrentChange"
              align="right"
            >
            </dd-pagination>
          </template>
        </dd-table>
      </dd-card>
      <!-- 新增期间计划 -->
      <dd-popup
        width="70%"
        height="80%"
        showBtn
        :saveBtn="true"
        :cancelBtn="true"
        :show="addPlanPopVisible"
        :title-name="poptitleName"
        @popup-close="handleClose"
        @popup-save="handleAddPlanSave('addPopForm', 'baseForm', 0)"
      >
        <dd-button
          slot="button"
          type="primary"
          size="medium"
          @click="handleAddPlanSave('addPopForm', 'baseForm', 1)"
          >发布</dd-button
        >
        <addPlanPop
          v-if="addPlanPopVisible"
          :rowData="rowData"
          :assignId="treeAssignId"
          slot="main"
          ref="addPop"
        >
        </addPlanPop>
      </dd-popup>
      <!-- 期间计划详情 -->
      <dd-popup
        width="70%"
        height="80%"
        :showBtn="false"
        :show="viewPlanPopVisible"
        title-name="详情"
        @popup-close="handleClose"
      >
        <detailPop
          v-if="viewPlanPopVisible"
          :rowData="rowData"
          :assignId="treeAssignId"
          slot="main"
          ref="detailPop"
        >
        </detailPop>
      </dd-popup>
      <!-- 完成详情 -->
      <dd-popup
        width="70%"
        height="80%"
        :showBtn="false"
        :show="viewFinishPlanPopVisible"
        title-name="完成详情"
        @popup-close="handleClose"
      >
        <finishDetailPop
          v-if="viewFinishPlanPopVisible"
          :rowData="rowData"
          :assignId="treeAssignId"
          slot="main"
          ref="finishDetailPop"
        >
        </finishDetailPop>
      </dd-popup>

      <dd-popup
        width="30%"
        height="24%"
        :showBtn="true"
        :saveBtn="false"
        :cancelBtn="true"
        :show="confirmationCompleted"
        title-name="完成详情"
        @popup-close="handleClose"
      >
      <dd-button slot="button" size="medium" type="primary"
        @click="handleConfirmationCompletedSave()">确认回退</dd-button>
      <div slot="main" v-if="confirmationCompleted">
        <dd-form
        :form-config="formList_confirmationCompleted"
        ref="confirmationCompletedForm"
        labelWidth="190px"
        :flex="1"
      ></dd-form>
      </div>
      </dd-popup>
    </div>
  </div>
</template>

<script>
import { getApi, postApi, downBlobApi, postDownBlobApi } from "@/api/request";
import editRealWorkLoad from "./components/EditRealWorkLoad.vue";
import addPlanPop from "./components/AddPlanPop.vue";
import detailPop from "./components/DetailPop.vue";
import finishDetailPop from "./components/FinishDetailPop.vue";
import { formatDateTime } from "@/utils/date";
export default {
  props: {
    resolveType: {
      type: String,
      default: "",
    },
    rowId: {
      type: String,
      default: "",
    },
  },
  components: {
    editRealWorkLoad,
    addPlanPop,
    detailPop,
    finishDetailPop,
  },
  data() {
    return {
      tableHeight: null,
      TreeList: [],
      defaultProps: {
        children: "children",
        label: "nodeName",
      },
      confirmationCompletedObj: {},
      formList_confirmationCompleted: [
      {
          label: "完工回退原因",
          value: "subplanType",
          inputType: "text",
          placeholderText: "请输入完工回退原因",
          rules: [
            { required: true, message: "请输入完工回退原因", trigger: "blur" },
          ],
        },
      ],
      confirmationCompleted: false,
      treeNodeId: "",
      loading: true,
      workActiveName: "ztjhjd",
      workTabList: [
        {
          label: "总体计划进度",
          value: "ztjhjd",
        },
        {
          label: "期间计划",
          value: "qjjhyhb",
        },
      ],
      nodeTableColumns: [
        {
          title: "节点编号",
          key: "nodeCode",
          width:'100',
        },
        {
          title: "节点名称",
          key: "nodeName",
          align: "center",
          overflow: true,
          width:'100',
        },
        {
          title: "节点类型",
          key: "nodeTypeName",
          align: "center",
          overflow: true,
          width:'100',
        },
        {
          title: "节点负责人",
          key: "nodeUser",
          align: "center",
          overflow: true,
          width:'100',
        },
        {
          title: "进度",
          align: "center",
          children: [
            {
              title: "进度状态",
              key: "nodeStateName",
              align: "center",
              overflow: true,
              width:'80',
            },
            {
              title: "工作量完成百分比",
              key: "percentageWorkload",
              align: "center",
              overflow: true,
              width:'150',
              formatter: (e) => {
                let val = "0%";
                if (e) {
                  val = e + "%";
                }
                return val;
              },
            },
            // {
            //   title: "进度权重",
            //   key: "planSchedulePercent",
            //   align: "center",
            // },
            // {
            //   title: "加权进度百分比",
            //   key: "percentageWeightCal",
            //   align: "center",
            //   formatter: (e) => {
            //     let val = "0%";
            //     if (e) {
            //       val = e + "%";
            //     }
            //     return val;
            //   },
            // },
            {
              title: "标准工天完成百分比",
              key: "standardWorkdayWeightCal",
              align: "center",
              overflow: true,
              width:'150',
              formatter: (e) => {
                let val = "0%";
                if (e) {
                  val = e + "%";
                }
                return val;
              },
            },
          ],
        },
        {
          title: "任务量单位",
          key: "nodeUnitName",
          align: "center",
          overflow: true,
          width:'100',
        },
        {
          title: "计划",
          align: "center",
          children: [
            {
              title: "计划总工作量",
              key: "planWorkload",
              align: "center",
              overflow: true,
              width:'120',
            },
            {
              title: "计划标准工天",
              key: "planStandardWorkday",
              align: "center",
              overflow: true,
              width:'120',
            },
            {
              title: "计划开始时间",
              key: "planStarttime",
              align: "center",
              overflow: true,
              width:'120',
            },
            {
              title: "计划结束时间",
              key: "planEndtime",
              align: "center",
              overflow: true,
              width:'120',
            },
          ],
        },
        {
          title: "实际",
          align: "center",
          children: [
            {
              title: "实际工作量",
              key: "realWorkload",
              align: "center",
              overflow: true,
              width:'120',
            },
            {
              title: "实际标准工天",
              key: "realStandardWorkday",
              align: "center",
              overflow: true,
              width:'120',
            },
            {
              title: "实际开始时间",
              key: "realStarttime",
              align: "center",
              overflow: true,
              width:'120',
            },
            {
              title: "实际结束时间",
              key: "realEndtime",
              align: "center",
              overflow: true,
              width:'120',
            },
          ],
        },
        {
          title: "操作",
          key: "handle",
          align: "center",
          width: 200,
          fixed: "right",
          event: [
            {
              label: "编辑实际量",
              icon: "el-icon-edit",
              method: (row) => {
                this.handleRowEditor(row);
              },
              type: "primary",
              rules: (row) => {
                let show = false;
                if (
                  row.nodeState == "PRJ-RWJHJDZT-SCZ" &&
                  row.nodeType != "PRJ-RWJHJDLX-GZB" &&
                  row.nodeType != "PRJ-RWJHJDLX-ZJDWX"
                ) {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              label: "完工确认",
              icon: "el-icon-check",
              method: (row) => {
                this.handleVerify(row);
              },
              type: "primary",
              rules: (row) => {
                let show = false;
                if (row.nodeState == "PRJ-RWJHJDZT-SCZ") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              label: "完工确认回退",
              icon: "el-icon-check",
              method: (row) => {
                this.handleComplete(row);
              },
              type: "primary",
              rules: (row) => {
                let show = false;
                if (row.nodeState == "PRJ-RWJHJDZT-WC") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
          ],
        },
      ],
      nodeTableData: [],
      addPopupVisible: false,
      rowData: null,
      docTableColumns: [
        {
          title: "计划类型",
          key: "subplanTypeName",
          align: "center",
          width:'120',
        },
        {
          title: "计划名称",
          key: "subplanName",
          align: "center",
          width:'120',
        },
        {
          title: "计划执行开始时间",
          key: "subplanStarttime",
          align: "center",
          width:'150',
        },
        {
          title: "计划执行结束时间",
          key: "subplanEndtime",
          align: "center",
          width:'150',
        },
        {
          title: "计划执行部门",
          key: "applierDeptName",
          align: "center",
          width:'150',
        },
        {
          title: "计划状态",
          key: "subplanStatusName",
          align: "center",
          width:'100',
        },
        {
          title: "实际执行开始时间",
          key: "realStarttime",
          align: "center",
          width:'150',
        },
        {
          title: "实际执行结束时间",
          key: "realEndtime",
          align: "center",
          width:'150',
        },
        {
          title: "操作",
          key: "handle",
          align: "center",
          width: 240,
          event: [
            {
              label: "编辑",
              icon: "el-icon-edit",
              method: (row) => {
                this.handleRowPlanEditor(row);
              },
              type: "warning",
              rules: (row) => {
                let show = false;
                if (row.subplanStatus == "PRJ-JDJHZT-ZC") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              label: "删除",
              icon: "el-icon-delete",
              method: (row) => {
                this.handleRowDel(row);
              },
              type: "danger",
              rules: (row) => {
                let show = false;
                if (row.subplanStatus == "PRJ-JDJHZT-ZC") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              label: "导出",
              icon: "el-icon-download",
              method: (row) => {
                this.handleExport(row);
              },
              type: "primary",
              rules: (row) => {
                let show = false;
                if (row.subplanStatus == "PRJ-JDJHZT-JS") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              label: "查看计划",
              icon: "el-icon-view",
              method: (row) => {
                this.handleRowDetail(row);
              },
              rules: (row) => {
                let show = false;
                if (row.subplanStatus == "PRJ-JDJHZT-ZC") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              label: "完成详情",
              icon: "el-icon-view",
              method: (row) => {
                this.handleFinishDetail(row);
              },
              rules: (row) => {
                let show = false;
                if (row.subplanStatus == "PRJ-JDJHZT-ZXZ"||row.subplanStatus == "PRJ-JDJHZT-JS") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              label: "执行结束",
              icon: "el-icon-check",
              method: (row) => {
                this.handleReportTask(row);
              },
              type: "warning",
              rules: (row) => {
                let show = false;
                if (
                  row.subplanStatus == "PRJ-JDJHZT-ZXZ"
                ) {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
          ],
        },
      ],
      docTableData: [],
      addPlanPopVisible: false,
      poptitleName: "新增计划",
      current: 1,
      size: 20,
      total: null,
      viewPlanPopVisible: false,
      viewFinishPlanPopVisible:false,
      // 表格选择数据集合
      multipleSelection: [],
      treeAssignId: null,
      subplanType: null,
    };
  },
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.treeAssignId = this.rowId;
    this.getTableData();
  },
  methods: {
    handleImport(){
      console.log("handleImport");
      
    },
    handlePlanExport(){
      console.log("handlePlanExport");
    },
    // 获取表格列表数据
    getTableData() {
      this.loading = false;
      let params = {
        id: this.treeNodeId,
      };
      getApi(
        `/project/subplan/getsubplanenabletree/${this.treeAssignId}`,
        params
      ).then((res) => {
        let { data } = res;
        if (data && data.code == 0 && data.data) {
          this.defaultProps.label = "nodeName";
          if (!this.treeNodeId) {
            this.TreeList = data.data;
            this.treeNodeId = data.data[0].id;
            this.$nextTick(() => {
              this.$refs.tree.setCurrentKey(this.treeNodeId); //一定要加这个选中了否则样式没有出来
            });
          }
          this.nodeTableData = data.data;
          this.loading = false;
        }
      });
    },
    handleNodeClick(data) {
      this.treeNodeId = data.id;
      this.treeAssignId = data.assignId;
      if (this.workActiveName == "qjjhyhb") {
        this.subplanType = data.subplanType;
        this.getPrjAssignperiodPlanPage();
      } else {
        this.getTableData();
      }
    },
    handleTabClick(tab, event) {
      this.$nextTick(() => {
        this.tableHeight = this.autoTableHeight(0);
      });
      this.workActiveName = tab.name;
      this.treeNodeId = null;
      this.treeAssignId = this.rowId;
      if (this.workActiveName == "qjjhyhb") {
        this.getPrjAssignPeriodPlanTree();
        this.getPrjAssignperiodPlanPage();
      } else {
        this.getTableData();
      }
    },
    handleRowEditor(row) {
      this.rowData = row;
      this.addPopupVisible = true;
    },
    // 编辑实际量
    handleRealWorkSave(formName, formNameBase) {
      let flag = false;
      this.$refs.editPop.$refs[formName].$refs[formNameBase].validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      let params = this.$refs.editPop.$refs[formName].formData;
      if (flag) {
        params.id = this.rowData.id;
        postApi(`/project/subplannode/updatesubmasterplanbyid`, params).then(
          (res) => {
            let { data } = res;
            if (data && data.code == 0) {
              this.handleClose();
              this.getTableData();
            }
          }
        );
      }
    },
    handleClose() {
      this.rowData = null;
      this.addPopupVisible = false;
      this.addPlanPopVisible = false;
      this.viewPlanPopVisible = false;
      this.viewFinishPlanPopVisible = false;
      this.confirmationCompleted = false;
    },
    handleConfirmationCompletedSave() {
      let flag = false;
      this.$refs.confirmationCompletedForm.$refs.baseForm.validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      if (!flag) {
        return;
      }
      let reason = this.$refs.confirmationCompletedForm.formData.subplanType;
      postApi(`/project/subplannode/rollbacksubmasterplanbyid/${this.confirmationCompletedObj.id}`,{reason}).then(res=> {
        if(res.data.code === 0) {
          console.log('res.data.data', res.data.data)
          this.$message.success('操作成功')
        }
        else{
          this.$message.warning('操作失败，请稍后重试')
        }
        this.getTableData();
        this.confirmationCompleted = false;
      })
    },
    handleComplete(row) {
      this.confirmationCompletedObj = row;
      this.confirmationCompleted = true;
    },
    // 完工确认
    handleVerify(row) {
      this.$confirm(`确认要进行此操作吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        postApi(`/project/subplannode/confirmsubmasterplanbyid/${row.id}`).then(
          (res) => {
            let { data } = res;
            if (data && data.code == 0) {
              this.getTableData();
            }
          }
        );
      });
    },
    handleAdd() {
      this.poptitleName = "新增计划";
      this.addPlanPopVisible = true;
    },
    getPrjAssignPeriodPlanTree() {
      getApi(`/project/subplan/getprjassignperiodplantree/${this.rowId}`).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            this.defaultProps.label = "name";
            this.TreeList = data.data;
            this.treeNodeId = data.data[0].id;
            this.$nextTick(() => {
              this.$refs.tree.setCurrentKey(this.treeNodeId); //一定要加这个选中了否则样式没有出来
            });
          }
        }
      );
    },
    getPrjAssignperiodPlanPage() {
      let { current, size } = this;
      let params = {
        assignId: this.treeAssignId,
        subplanType: this.subplanType,
        current,
        size,
      };
      getApi(`/project/subplan/getprjassignperiodplanpage`, params).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            this.docTableData = data.data.records;
            this.total = data.data.total;
            this.clearTableSelection();
          }
        }
      );
    },
    // 期间计划编辑
    handleRowPlanEditor(row) {
      this.poptitleName = "编辑";
      this.rowData = row;
      this.addPlanPopVisible = true;
    },
    // 期间计划删除
    handleRowDel(row) {
      this.$confirm(`确认要删除此数据吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        let params = {
          ids: [row.id],
        };
        postApi(`/project/subplan/deleteprjassignperiodplan`, params).then(
          (res) => {
            let { data } = res;
            if (data && data.code == 0) {
              this.getPrjAssignperiodPlanPage();
              this.getPrjAssignPeriodPlanTree();
            }
          }
        );
      });
    },
    handleRowDetail(row) {
      this.rowData = row;
      this.viewPlanPopVisible = true;
    },
    // 完成详情
    handleFinishDetail(row) {
      this.rowData = row;
      this.viewFinishPlanPopVisible = true;
    },
    // 新增计划保存
    handleAddPlanSave(formName, formNameBase, type) {
      let flag = false;
      this.$refs.addPop.$refs[formName].$refs[formNameBase].validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      if (!flag) {
        return;
      }
      let params = this.$refs.addPop.$refs[formName].formData;
      let u_i_data = {
        applierDeptId:params.applierDeptId,
        prjAssignSubplanPeriodNodeVOList:this.$refs.addPop.tableDataSources,
        subplanStarttime:params.taskDuration[0],
        subplanEndtime:params.taskDuration[1],
        subplanType:params.subplanType,
        assignId:this.rowId,
      }
      let date1 = new Date(params.taskDuration[0]);
      let date2 = new Date(params.taskDuration[1]);
      let diffTime = Math.abs(date2 - date1);
      let diffDays = diffTime / (1000 * 60 * 60 * 24)
      // 月计划
      if(u_i_data.subplanType == 'PRJ-JDJHLX-MONTH' && diffDays <= 7){
        this.$message.warning("计划结束时间与计划开始时间差大于7日!");
        return;
      }
      // 周计划
      else if(u_i_data.subplanType == 'PRJ-JDJHLX-WEEK' && diffDays > 7){
        this.$message.warning("计划结束时间与计划开始时间的差小于等于7日");
        return;
      }
      // 日计划
      else if(u_i_data.subplanType == 'PRJ-JDJHLX-DAY' && diffDays != 1){
        this.$message.warning("计划结束时间与计划开始时间的差小于等于1日");
        return;
      }
      if (u_i_data.prjAssignSubplanPeriodNodeVOList.length == 0) {
        this.$message.warning("请选择计划节点");
        return;
      }
      const cellEdit = u_i_data.prjAssignSubplanPeriodNodeVOList.every((obj) => {
        return Object.keys(obj).every((key) => {
          if (
            obj.planStandardWorkday != null &&
            obj.planWorkload != null &&
            obj.planStandardWorkday != "" &&
            obj.planWorkload != ""
          ) {
            return true;
          }
        });
      });
      if (!cellEdit) {
        this.$message.warning("请填写计划标准工天和计划工作量");
        return;
      }
      if (this.rowData == null && this.poptitleName == "新增计划") {
        postApi(`/project/subplan/addprjassignperiodplan/${type}`, u_i_data).then(
          (res) => {
            let { data } = res;
            if (data && data.code == 0) {
              this.handleClose();
              this.getPrjAssignperiodPlanPage();
              this.getPrjAssignPeriodPlanTree();
            }
          }
        );
      } else {
        u_i_data.id = this.rowData.id;
        postApi(
          `/project/subplan/updateprjassignperiodplan/${type}`,
          u_i_data
        ).then((res) => {
          let { data } = res;
          if (data && data.code == 0) {
            this.handleClose();
            this.getPrjAssignperiodPlanPage();
            this.getPrjAssignPeriodPlanTree();
          }
        });
      }
    },
    handleAllExport() {
      let params = {
        id: this.treeAssignId,
      };
      postDownBlobApi(
        `/project/subplan/schedule/export`,
        params,
        "总体计划进度"
      ).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
        }
      });
    },
    // 导出
    handleExport(row) {
      downBlobApi(
        `/project/subplan/exportprjassignperiodplan/${row.id}`,
        "",
        row.subplanName
      ).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.getPrjAssignperiodPlanPage();
        }
      });
    },
    // 表格多选回调事件
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 清除表格多选状态
    clearTableSelection() {
      this.$refs.qjjhyhbTable.$refs.baseTable.clearSelection();
      this.multipleSelection = [];
    },
    // 计划发布
    handleSubmit() {
      let submitSection = this.multipleSelection;
      let ids = [];
      if (submitSection.length < 1) {
        this.$message.warning("请至少选择一条数据!");
        return;
      } else {
        let isOk = true;
        submitSection.length &&
          submitSection.map((item) => {
            if (item.subplanStatus != "PRJ-JDJHZT-ZC") {
              isOk = false;
              this.$message.warning("只能选择暂存状态数据!");
              ids = [];
            }
            if (isOk) {
              ids.push(item.id);
            }
          });
      }
      if (ids && ids.length > 0) {
        let params = {
          ids: ids,
        };
        postApi(`/project/subplan/releaseprjassignperiodplan`, params).then(
          (res) => {
            let { data } = res;
            if (data && data.code == 0) {
              this.getPrjAssignperiodPlanPage();
            }
          }
        );
      }
    },
    // 生成汇报
    handleReportTask(row) {
      this.$confirm(`确认要进行此操作吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        getApi(`/project/subplan/generateprjassignperiodplan/${row.id}`).then(
          (res) => {
            let { data } = res;
            if (data && data.code == 0) {
              this.getPrjAssignperiodPlanPage();
            }
          }
        );
      });
    },
    /* 分页页码回调 */
    handleCurrentChange(size, number) {
      this.current = number;
      this.size = size;
      this.getPrjAssignperiodPlanPage();
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.nodeType === "PRJ-RWJHJDLX-ZRW") {
        return "zrw";
      } else if (row.nodeType === "PRJ-RWJHJDLX-GZB") {
        return "gzb";
      } else if (row.nodeState == "PRJ-RWJHJDZT-JY") {
        return "jy";
      }
    },
    tableCellClassName({ row, column, rowIndex, columnIndex }) {
      if (
        column.property == "nodeStateName" &&
        row.nodeState == "PRJ-RWJHJDZT-SCZ"
      ) {
        return "scz";
      } else if (
        column.property == "nodeStateName" &&
        row.nodeState == "PRJ-RWJHJDZT-WC"
      ) {
        return "wc";
      } else if (
        column.property == "nodeStateName" &&
        row.nodeState == "PRJ-RWJHJDZT-ZC"
      ) {
        return "zc";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.tab-container {
  height: 100%;
  display: flex;
  .left-tree {
    width: 20%;
    height: 100%;
    padding: 10px 15px;
    box-sizing: border-box;
    border-right: 5px solid #e7eaec;
    overflow-y: auto;
    .custom-tree-node {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: @fs14;
      .zrw {
        color: #0020c5;
      }

      .gzb {
        color: #159d9d;
      }

      .node-hanlde {
        color: @defaultFontColor;
        i {
          margin: 0 5px;
        }
      }
    }
  }
  .right-table {
    width: 80%;
    height: 100%;
    .table-tab {
      /deep/ .card-contant {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 0;
        .el-tabs {
          width: 100%;
        }
      }
      /deep/ .el-tabs__header {
        margin: 0;
      }
    }
    /deep/.el-table__expand-icon {
      display: none !important;
    }
    /deep/ .el-table__indent {
      display: none !important;
    }
    /deep/.el-table__placeholder {
      display: none !important;
    }
    /deep/.el-table .zrw {
      td.el-table__cell {
        background-color: #cadaff;
        color: #0020c5;
      }
    }

    /deep/.el-table .gzb {
      td.el-table__cell {
        background-color: #159d9d;
        color: #fff;
      }
    }
    /deep/.el-table .jy {
      td.el-table__cell {
        background-color: #a9a9a9;
        color: #fff;
      }
    }
    /deep/ .el-table {
      td.scz {
        background-color: #0f55ab !important;
        color: #fff !important;
      }
      td.wc {
        background-color: #07b107 !important;
        color: #fff !important;
      }
      td.zc {
        background-color: #df8e3b !important;
        color: #fff !important;
      }
    }
  }
}
</style>
