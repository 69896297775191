<template>
  <div
    class="base-form"
    :style="`--width: ${flex == 4 ? '25%' : flex == 3 ? '33.3%' : flex == 2 ? '50%' : '100%'}`"
  >
    <el-form
      :model="formData"
      :label-width="labelWidth"
      :ref="formRef"
      @submit.native.prevent
    >
      <div class="base-form-group">
        <el-form-item
          class="base-form-input"
          v-for="(item, index) in formConfig"
          :key="index"
          :label="item.label"
          :prop="item.value"
          :rules="item.show == undefined || item.show ? item.rules : []"
          :style="
            item.show == undefined || item.show
              ? 'display:flex'
              : 'display:none'
          "
        >
          <!-- 默认文本框inputType=text -->
          <el-input
            v-if="item.inputType == 'text'"
            v-model="formData[item.value]"
            :title="formData[item.value]"
            :type="item.type"
            :placeholder="item.placeholderText"
            :disabled="item.disabled"
            :readonly="item.readonly"
            :autosize="item.autosize"
            :ref="item.ref"
            clearable
            @blur="item.change ? item.change($event, item.value) : occupyingFn"
            @input="item.input ? item.input($event, item.value) : occupyingFn"
            :rows="item.rows"
            @click.native="item.click ? item.click(item.value) : occupyingFn"
            class="input-value"
          >
          </el-input>
          <!-- 按钮选择输入框inputType=buttonInput -->
          <el-input
            v-if="item.inputType == 'buttonInput'"
            v-model="formData[item.value]"
            :type="item.type"
            :placeholder="item.placeholderText"
            :disabled="item.disabled"
            :readonly="item.readonly"
            :autosize="item.autosize"
            :ref="item.ref"
            clearable
            @blur="item.change ? item.change($event, item.value) : occupyingFn"
            :rows="item.rows"
            class="input-value"
          >
            <el-button
              slot="append"
              icon="el-icon-plus"
              @click="item.handleEvent"
            ></el-button>
          </el-input>
          <!-- 数字输入框inputType=number -->
          <el-input-number
            v-if="item.inputType == 'number'"
            v-model="formData[item.value]"
            controls-position="right"
            :min="item.min"
            :max="item.max"
          >
          </el-input-number>
          <!-- 下拉选择框inputType=select -->
          <el-select
            v-if="item.inputType == 'select'"
            v-model="formData[item.value]"
            :placeholder="item.placeholderText"
            :disabled="item.disabled"
            :multiple="item.multiple"
            :filterable="item.filterable"
            :ref="item.ref"
            :remote="item.remote ? item.remote : false"
            :remote-method="item.remoteMethod ? item.remoteMethod : occupyingFn"
            clearable
            class="input-value"
            @change="
              item.change ? item.change($event, item.value) : occupyingFn
            "
            @clear="item.clear ? item.clear($event, item.value) : occupyingFn"
          >
            <el-option
              v-for="val in item.optionsList"
              :key="
                val[
                  item.optionsListConfig ? item.optionsListConfig.code : 'code'
                ]
              "
              :label="
                val[
                  item.optionsListConfig ? item.optionsListConfig.name : 'name'
                ]
              "
              :value="
                val[
                  item.optionsListConfig ? item.optionsListConfig.code : 'code'
                ]
              "
            ></el-option>
          </el-select>
          <!-- 附件上传inputType=upload -->
          <el-upload
            v-if="item.inputType == 'upload'"
            class="avatar-uploader"
            :show-file-list="false"
            :multiple="false"
            :list-type="item.pictureType"
            :action="item.action"
            :headers="item.headers"
            :disabled="item.disabled"
            :on-success="item.successEvent"
            :limit="item.limit"
            :accept="item.accept"
            :ref="item.ref"
          >
            <div
              :id="'imgbox' + item.value"
              class="image-box"
              v-if="formData[item.value]"
              @mouseenter.stop.prevent="handleMouseEnter('imgbox' + item.value)"
              @mouseleave.stop.prevent="handleMouseLeave('imgbox' + item.value)"
            >
              <img :src="formData[item.value]" />
              <div class="upload-handle" @click.stop>
                <div
                  class="handle-icon"
                  @click.stop="openImgViewVisible('img' + item.value)"
                >
                  <span class="el-icon-zoom-in"></span>
                </div>
                <div
                  v-if="!item.disabled"
                  class="handle-icon"
                  @click.stop="item.removeEvent(item.value)"
                >
                  <span class="el-icon-delete"></span>
                </div>
              </div>
            </div>
            <div v-else>
              <i
                class="iconfont"
                :class="item.icon ? item.icon : 'icon-tianjia'"
              ></i>
            </div>
            <el-image-viewer
              style="display: none"
              :id="'img' + item.value"
              :url-list="[formData[item.value]]"
            />
          </el-upload>
          <!-- 级联选择框inputType=cascader -->
          <el-cascader
            v-if="item.inputType == 'cascader'"
            v-model="formData[item.value]"
            :options="item.optionsList"
            :props="item.prop"
            :placeholder="item.placeholderText"
            :disabled="item.disabled"
            :show-all-levels="item.showAllSelcet"
            class="input-value"
            ref="cascader"
            @change="
              item.change
                ? item.change($event, 'cascader', item.value)
                : occupyingFn
            "
            clearable
          ></el-cascader>
          <!-- 日期选择器inputType=date -->
          <el-date-picker
            v-if="item.inputType == 'date'"
            v-model="formData[item.value]"
            :type="item.dateType"
            :disabled="item.disabled"
            :placeholder="item.placeholderText"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :value-format="item.format"
            :default-time="item.defaultValue"
            :format="item.format"
            class="input-value"
            unlink-panels
            @change="
              item.change ? item.change($event, item.value) : occupyingFn
            "
          >
          </el-date-picker>
          <!-- 只选择日期组件 inputType=dateStr type:date datetime -->
          <el-date-picker
            v-if="item.inputType == 'dateStr'"
            v-model="formData[item.value]"
            :disabled="item.disabled"
            :default-time="item.defaultTime"
            :placeholder="item.placeholderText"
            :type="item.dateType"
            :value-format="item.format"
            :ref="item.ref"
            class="input-value"
            @change="
              item.change ? item.change($event, item.value) : occupyingFn"
          >
          </el-date-picker>
          <!-- 其他日期选择 inputType=otherDate  tyee:year年;month月;week周；-->
          <el-date-picker
            v-if="item.inputType == 'otherDate'"
            v-model="formData[item.value]"
            :disabled="item.disabled"
            :placeholder="item.placeholderText"
            :multiple="item.multiple"
            :format="item.displayFormat"
            :value-format="item.format"
            :ref="item.ref"
            :type="item.dateType"
            class="input-value"
            @change="
              item.change ? item.change($event, item.value) : occupyingFn"
          >
          </el-date-picker>
          <!-- 单选按钮组inputType=radio-->
          <el-radio-group
            v-if="item.inputType == 'radio'"
            v-model="formData[item.value]"
            :disabled="item.disabled"
            @input="item.change ? item.change($event, item.value) : occupyingFn"
          >
            <el-radio-button
              :border="item.border"
              v-for="(val, idx) in item.radioGroup"
              :key="idx"
              :label="val.value"
              >{{ val.label }}</el-radio-button
            >
          </el-radio-group>
          <el-link 
          v-if="item.inputType == 'link'"
          :underline="false"
          type="primary"
          @click.native="item.click ? item.click(item.value, formData) : occupyingFn"
          >{{ formData[item.value] }}</el-link>

          <dd-button v-if="item.inputType == 'button'" type="primary" size="medium" icon="add" @click.native="item.click ? item.click() : occupyingFn"
            >{{ item.Btnlabel }}</dd-button
          >
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import $ from "jquery";
export default {
  name: "dd-form",
  components: {
    ElImageViewer,
  },
  props: {
    formConfig: {
      type: Array,
      default: [],
    },
    formRef: {
      type: String,
      default: "baseForm",
    },
    flex: {
      type: Number,
      default: 1,
    },
    labelWidth: {
      type: String,
      default: "120px",
    },
  },
  data() {
    return {
      formData: {},
      imgViewVisible: false,
    };
  },
  mounted() {
    this.getFormData();
  },
  watch: {
    formConfig: {
      handler() {
        this.getFormData();
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    handleMouseEnter(id) {
      $("#" + id + " .upload-handle").css({ display: "flex" });
    },
    handleMouseLeave(id) {
      $("#" + id + " .upload-handle").css({ display: "none" });
    },
    // 图片预览事件
    openImgViewVisible(id) {
      let imgDom = document.getElementById(id);
      imgDom.style.display = "block";
      this.$nextTick(() => {
        imgDom
          .querySelector(".el-image-viewer__close")
          .addEventListener("click", () => {
            document.getElementById(id).style.display = "none";
          });
      });
    },
    //占位函数
    occupyingFn() {},
    getFormData() {
      let obj = {};
      this.formConfig &&
        this.formConfig.forEach((item) => {
          if (item.defaultValue) {
            obj[item.value] = item.defaultValue;
          } else {
            if (item.inputType == "date") {
              obj[item.value] = [];
            } else {
              obj[item.value] = "";
            }
          }
        });
      this.$nextTick(() => {
        this.formData = obj;
      });
    },
    cascaderChange() {
      this.$refs.cascader[0].dropDownVisible = false;
    },
    handlePictureCardPreview(refName) {
      this.$refs[refName + "1"][0].showViewer = true;
    },
  },
};
</script>

<style lang="less" scoped>
.base-form {
  .base-form-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .avatar-uploader {
      .image-box {
        width: 100%;
        height: 100%;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          border-radius: 6px;
        }

        .upload-handle {
          position: absolute;
          top: 0;
          right: 0;
          box-sizing: border-box;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          cursor: pointer;
          background: rgb(0 0 0 / 60%);
          border-radius: 6px;
          display: none;
          .handle-icon {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0 8px;
            color: #fff;
            span {
              font-size: @fs18;
            }
          }
        }
      }
    }
    .base-form-input {
      width: var(--width);
      display: flex;
      align-items: center;
      margin: 10px 0;

      /deep/ .el-form-item__content {
        width: 100%;
      }

      .input-value {
        width: 100%;
      }
    }
  }

  /deep/ .el-form-item__content {
    margin-left: 0px !important;
    display: flex;
  }

  /deep/ .el-upload__tip i {
    color: #f56c6c;
  }

  /deep/ .el-form-item__label {
    display: flex;
    align-items: center;
    font-weight: bold;
    justify-content: flex-end;
    margin-right: 15px;
    padding: 0;
  }
  /deep/ .el-radio-button__inner {
    padding: 8px 15px;
  }
  /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background-color: @primaryBtn;
    border-color: @primaryBtn;
  }
  /deep/ .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
    i {
      line-height: inherit;
    }
  }
  /deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 100px;
    height: 100px;
    margin: 0;
  }
  /deep/ .hide .el-upload--picture-card {
    display: none;
  }
}
</style>
