import { render, staticRenderFns } from "./contractDetail.vue?vue&type=template&id=5a96b1b9&scoped=true"
import script from "./contractDetail.vue?vue&type=script&lang=js"
export * from "./contractDetail.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a96b1b9",
  null
  
)

export default component.exports