<template>
  <div class="container">
    <dd-card title="">
      <dd-form
        :form-config="formList"
        ref="addPopForm"
        formRef="addPopForm"
        :flex="2"
        labelWidth="180px"
      ></dd-form>
    </dd-card>
  </div>
</template>

<script>
import { postApi, getApi, loadApi } from "@/api/request";
export default {
  name: "addEditDetails",
  props: ["pageProp"],
  data() {
    return {
      formList: [
        {
          label: "单据编号",
          value: "code",
          inputType: "text",
          placeholderText: "提交后生成",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "记录日期",
          value: "recordDate",
          placeholderText: "记录日期",
          inputType: "date",
          dateType: "date",
          filterable: true,
          defaultValue: this.$moment().format("YYYY-MM-DD"),
          disabled: this.pageProp.pageType == "detail",
          format: "yyyy-MM-dd",
          rules: [
            { required: true, message: "请选择记录日期", trigger: "blur" },
          ],
        },
        {
          label: "自建采购合同名称",
          value: "selfContractName",
          inputType: "select",
          optionsList: [],
          optionsListConfig: {
            code: "id",
            name: "name",
          },
          defaultValue: "",
          filterable: true,
          change: this.handleSelectChange,
          placeholderText: "请选择自建采购合同名称",
          disabled: this.pageProp.pageType == "detail",
          rules: [
            {
              required: true,
              message: "请选择自建采购合同名称",
              trigger: "blur",
            },
          ],
        },
        {
          label: "自建采购合同编号",
          value: "selfContractCode",
          inputType: "select",
          optionsList: [],
          optionsListConfig: {
            code: "id",
            name: "code",
          },
          defaultValue: "",
          filterable: true,
          change: this.handleSelectChange,
          placeholderText: "请选择自建采购合同编号",
          disabled: this.pageProp.pageType == "detail",
          rules: [
            {
              required: true,
              message: "请选择自建采购合同编号",
              trigger: "blur",
            },
          ],
        },
        {
          label: "金蝶合同名称",
          value: "kingContractName",
          inputType: "select",
          optionsList: [],
          optionsListConfig: {
            code: "purchaseContractId",
            name: "fname",
          },
          defaultValue: "",
          filterable: true,
          change: this.handleKingSelectChange,
          placeholderText: "请选择金蝶合同名称",
          disabled: this.pageProp.pageType == "detail",
          // rules: [
          //   { required: true, message: "请选择金蝶合同名称", trigger: "blur" },
          // ],
        },
        {
          label: "金蝶合同编号",
          value: "kingContractCode",
          inputType: "select",
          optionsList: [],
          optionsListConfig: {
            code: "purchaseContractId",
            name: "fnumber",
          },
          defaultValue: "",
          filterable: true,
          change: this.handleKingSelectChange,
          placeholderText: "请选择金蝶合同编号",
          disabled: this.pageProp.pageType == "detail",
          // rules: [
          //   { required: true, message: "请选择金蝶合同编号", trigger: "blur" },
          // ],
        },
        {
          label: "销售合同名称",
          value: "saleContractName",
          inputType: "select",
          filterable: true,
          optionsList: [],
          optionsListConfig: {
            name: "name",
            code: "name",
          },
          rules: [
            {
              required: true,
              message: "请选择销售合同名称",
              trigger: "blur",
            },
          ],
          placeholderText: "请选择销售合同名称",
          disabled: this.pageProp.pageType == "detail",
          remote: true,
          change: ($event, value) => {
            this.changesaleContract($event, value, "name");
          },
          remoteMethod: (query) => {
            this.queryContarctList({ name: query });
          },
        },
        {
          label: "销售合同编号",
          value: "saleContractCode",
          inputType: "select",
          optionsList: [],
          filterable: true,
          disabled: this.pageProp.pageType == "detail",
          optionsListConfig: {
            name: "code",
            code: "code",
          },
          rules: [
            {
              required: true,
              message: "请选择销售合同编号",
              trigger: "blur",
            },
          ],
          change: ($event, value) => {
            this.changesaleContract($event, value, "code");
          },
          remote: true,
          remoteMethod: (query) => {
            this.queryContarctList({ code: query });
          },
          placeholderText: "请选择销售合同编号",
        },
        {
          label: "销售合同甲方",
          value: "partAId",
          disabled: true,
          inputType: "text",
          placeholderText: "关联销售合同",
        },
        {
          label: "已用金额",
          value: "amount",
          inputType: "text",
          change: this.handleChange,
          placeholderText: "请输已用金额",
          disabled: this.pageProp.pageType == "detail",
          rules: [
            {
              required: true,
              message: "请输入已用金额",
              trigger: "blur",
            },
            {
              pattern: /^(-?\d*\.\d{1,2}|-?\d{1,9})$/,
              required: true,
              message: "请输入正确的格式",
              trigger: "blur",
            },
          ],
        },
        {
          label: "备注",
          placeholderText: "请输入备注",
          disabled: this.pageProp.pageType == "detail",
          value: "remark",
          inputType: "text",
          type: "textarea",
          defaultValue: "",
          rows: "2",
        },
      ],
      contarctList: [],
      selfContractObj: {},
      selfContracList: [],
      kingContractObj: {},
      kingContractList: [],
    };
  },
  created() {
    this.queryContarctList();
    this.getSelfContractList();
  },
  mounted() {
    if (this.pageProp.pageType != "add") {
      setTimeout((res) => {
        this.getPageData();
      }, 200);
    }
  },
  methods: {
    getPageData() {
      getApi(`/contract/specialrecord?id=${this.pageProp.id}`).then((res) => {
        if (res.data.code == 0) {
          if (res.data.data) {
            this.getKingList(res.data.data.selfContractId);
            this.formList.forEach((item) => {
              item.defaultValue = res.data.data[item.value];
            });
          }
        }
      });
    },
    handleKingSelectChange(e) {
      this.kingContractObj = {};
      if (e) {
        this.formList[4]?.optionsList?.forEach((item) => {
          if (item.purchaseContractId == e) {
            this.kingContractObj = item;
          }
        });
        this.$refs.addPopForm.formData.kingContractName =
          this.kingContractObj.fname;
        this.$refs.addPopForm.formData.kingContractCode =
          this.kingContractObj.fnumber;
        this.$refs.addPopForm.formData.kingContractId =
          this.kingContractObj.purchaseContractId;
      } else {
        this.$refs.addPopForm.formData.kingContractName = null;
        this.$refs.addPopForm.formData.kingContractCode = null;
        this.$refs.addPopForm.formData.kingContractId = null;
      }
    },
    handleSelectChange(e) {
      this.selfContractObj = {};
      if (e) {
        this.formList[2]?.optionsList?.forEach((item) => {
          if (item.id == e) {
            this.selfContractObj = item;
          }
        });
        this.$refs.addPopForm.formData.selfContractName =
          this.selfContractObj.name;
        this.$refs.addPopForm.formData.selfContractCode =
          this.selfContractObj.code;
        this.$refs.addPopForm.formData.selfContractId = this.selfContractObj.id;
        if (this.$refs.addPopForm.formData.selfContractId != "") {;
          this.getKingList(this.$refs.addPopForm.formData.selfContractId);
        }
        this.formList.forEach((item)=>{
          if(item.value == "kingContractName" || item.value == "kingContractCode"){
            item.defaultValue = '';
          }else{
            item.defaultValue = this.$refs.addPopForm.formData[item.value];
          }
        })
      } else {
        this.$refs.addPopForm.formData.selfContractName = null;
        this.$refs.addPopForm.formData.selfContractCode = null;
        this.$refs.addPopForm.formData.selfContractId = null;
        this.$refs.addPopForm.formData.kingContractName = null;
        this.$refs.addPopForm.formData.kingContractCode = null;
        this.$refs.addPopForm.formData.kingContractId = null;
      }
    },
    getKingList(id) {
      getApi(
        `/contract/selfestablished/procurement/record/query/king?id=${id}`
      ).then((res) => {
        if (res.data.code == 0) {
          this.kingContractList = res.data.data;
          this.formList.forEach((item) => {
            if (
              item.value == "kingContractCode" ||
              item.value == "kingContractName"
            ) {
              item.optionsList = res.data.data;
            } 
            else {
              item.defaultValue = this.$refs.addPopForm.formData[item.value];
            }
          });
        }
      });
    },
    changesaleContract($event, value, key) {
      if ($event) {
        this.contarctList.forEach((item) => {
          if (item[key] == $event) {
            if (key == "name") {
              this.$refs.addPopForm.formData.saleContractCode = item.code;
            } else {
              this.$refs.addPopForm.formData.saleContractName = item.name;
            }
            this.$refs.addPopForm.formData.partAId = item.partAId;
            this.$forceUpdate();
          }
        });
      } else {
        this.$refs.addPopForm.formData.saleContractCode = null;
        this.$refs.addPopForm.formData.saleContractName = null;
        this.$refs.addPopForm.formData.partAId = null;
      }
    },
    getSelfContractList() {
      postApi("/contract/selfestablished/procurement/record/query/list").then(
        (res) => {
          if (res.data.code == 0) {
            this.selfContracList = res.data.data;
            let { data } = res;
            let options1 = {
              value: "selfContractName",
              optionsList: data.data,
            };
            let options = {
              value: "selfContractCode",
              optionsList: data.data,
            };
            this.setOPtionsList(this.formList, options);
            this.setOPtionsList(this.formList, options1);
          }
        }
      );
    },
    queryContarctList(obj) {
      // 销售合同
      postApi(`/project/contractinfo/query`, obj).then((res) => {
        if (res.data.code == 0) {
          this.contarctList = res.data.data;
          this.formList[6].optionsList = res.data.data;
          this.formList[7].optionsList = res.data.data;
          let formData = this.$refs.addPopForm.formData;
          this.formList.forEach((item) => {
            item.defaultValue = formData[item.value];
          });
          this.$refs.addPopForm.formData.selfContractId =
            this.selfContractObj.id;
        }
      });
    },
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
  },
};
</script>

<style lang='less' scoped>
/deep/ .el-range-editor.is-disabled {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}

/deep/ .el-range-editor.is-disabled input {
  color: @defaultFontColor;
}

/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}

/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
  resize: none;
}
</style>