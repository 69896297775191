/*
 * @version: V1.0.0
 * @Author: hechen
 * @Date: 2023-03-24 16:28:50
 * @LastEditors: hechen
 * @LastEditTime: 2023-10-10 11:50:37
 * @company: 大地测绘
 * @Mailbox: 15291492336@163.com
 * @FilePath: \ssh-eom-web\src\main.js
 * @Descripttion: 
 */
import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import less from 'less'
import './assets/style/iconfont.css'
import {
  message
} from './assets/js/message';
import {
  showLoading,
  hideLoading
} from './assets/js/loading';
import store from './store/index'
import './components/autoRegister';
import {
  getStore,
  setStore
} from './utils/store';
import {
  autoTableHeight
} from './utils/autoTableHeight';
import '@/utils/dialog.js';
import moment from "moment"
import 'moment/locale/zh-cn'
moment.locale('zh-cn');
Vue.prototype.$moment = moment;
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(less);

Vue.prototype.$showLoading = showLoading;
Vue.prototype.$hideLoading = hideLoading;
Vue.prototype.$message = message;
Vue.prototype.getStore = getStore;
Vue.prototype.setStore = setStore;
Vue.prototype.autoTableHeight = autoTableHeight;

// const baseUrl = "http://192.168.128.49:9999"
// const baseUrl = "http://222.91.162.78:8086/pipeline-api"; //test
// const baseUrl = "http://192.168.128.113:9999"; //dev 
const activitiUrl = "http://192.168.128.91:18999/api"
Vue.prototype.$activitiUrl = activitiUrl;
// Vue.prototype.$baseUrl = baseUrl
router.beforeEach((to, from, next) => {
  const value = to.path;
  const query = to.query;
  const label = query.name || to.name;
  const meta = to.meta;
  if (meta.isTab !== false) {
    store.commit('ADD_TAG', {
      label: label,
      value: value,
      params: to.params,
      query: to.query,
      meta: to.meta
    })
  }
  next()
})
/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  store,
  router,
  components: {
    App
  },
  template: '<App/>',
  created() {},
  beforeCreate() {
    Vue.prototype.$bus = this //安装全局事件总线
  },
  methods: {}
}).$mount('#app')